import React, {useContext, useEffect} from 'react';
import {AuthContext} from '../context/AuthContext.js';

//Account variant imports
import ParticipantAccount from '../subpages/account/ParticipantAccount.js';
import FacilitatorAccount from '../subpages/account/FacilitatorAccount.js';

import '../styles/Account.css';

function Account({cbCancel}) {
  const {login, logout, accessLevel, loggedIn} = useContext(AuthContext);

  const cbLogout = () => {
    cbCancel();
    logout();
  };

  const renderAccountpage = () => {
    switch (accessLevel) {
      case 0:
        return <ParticipantAccount  cbCancel={cbCancel}
                                    cbLogout={cbLogout} />;
      case 1:
      case 2:
        return <FacilitatorAccount  cbCancel={cbCancel}
                                    cbLogout={cbLogout} />;
      default:
        return <div></div>;
    }
  };

  useEffect(() => {
    if (!loggedIn) {
      cbCancel();
    }
  }, []);

  return (
    <>
      <div className="container-account">
        {renderAccountpage()}
      </div>
    </>
  );
}

export default Account;
