import React from 'react';

import '../styles/components/Loading.css';

function Loading() {
  return (
    <>
      <div className="container-loadingfloat">
        <div className="loader"></div>
      </div>
    </>
  )
}

export default Loading;
