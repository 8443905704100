import React, {useContext, useState, useEffect} from 'react';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import {AuthContext} from '../context/AuthContext.js';

import SessionHeader from '../components/SessionHeader.js';

import '../styles/Login.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("");
  const {login, logout, loggedIn} = useContext(AuthContext);

  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    let loginObj = {
      email: email,
      password: password
    };

    axios.post(`${API_ROUTE}/auth/login`, loginObj, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      console.log(res);
      if (res.data.lid) {
        alert('Please update your password');
        navigate(`/reset_password/${res.data.lid}`);
      } else {
        login(res.data.accessLevel);
        navigate('/');
      }
    }).catch((err) => {
      console.log(err);
      setStatus(err.response.data.message);
    });
  };

  return (
    <>
      <SessionHeader pageName="" pageInfo="" />

      <div className="container-login">
        <div className="title">Login</div>
        <div className="container-inputs">
          <div className="label">Email: </div>
          <input  id={`login-email`}
                  className="input-login"
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  required />
          <div className="label">Password: </div>
          <input  id={`login-password`}
                  className="input-login"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required />
          <div></div>
          <div>
            <Link to="/forgot_password">Reset Password</Link>
          </div>
          <div className="status">
            {status}
          </div>
        </div>

        <div className="container-buttons">
          <button id="login-button" onClick={handleLogin}>Login</button>
        </div>
      </div>
    </>
  );
}

export default Login;
