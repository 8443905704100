import React from 'react';

import '../styles/components/Confirmation.css';

function Confirmation({cbDelete, cbCancel, message, buttonName}) {
  return (
    <>
      <div className="container-confirmationfloat">
        <div className="container-confirmation">
          <p>{message}</p>
          <div className="container-buttons">
            <button className="button-confirmation delete" onClick={cbDelete}>{buttonName ? buttonName : "Delete"}</button>
            <button className="button-confirmation cancel" onClick={cbCancel}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Confirmation;
