import React, {useState, useEffect} from 'react';
import axios from 'axios';

import AdminHeader from '../../components/AdminHeader.js';

//Subpage imports
import Events from '../admin/Events.js';
import Participants from '../admin/Participants.js';
import Facilitators from '../admin/Facilitators.js';
import SuperAdmins from '../admin/SuperAdmins.js';
import ExpiredEvents from '../admin/ExpiredEvents.js';
import TeamOutput from '../admin/TeamOutput.js';
import Account from '../Account.js';

import '../../styles/home/SuperAdminHome.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function SuperAdminHome() {
  const [pageNumber, setPageNumber] = useState(0);
  const [expiredEventCount, setExpiredEventCount] = useState(0);

  useEffect(() => {
    axios.get(`${API_ROUTE}/event/all_expired`, {
      withCredentials: true
    }).then((res) => {
      setExpiredEventCount(res.data.events.length);
    }).catch((err) => {
      console.log(err);
    });
  }, [pageNumber]);

  //Handle input functions
  const setEventsPage = () => {
    setPageNumber(0);
  }
  const setParticipantsPage = () => {
    setPageNumber(1);
  }
  const setFacilitatorsPage = () => {
    setPageNumber(2);
  }
  const setSuperAdminsPage = () => {
    setPageNumber(3);
  }
  const setExpiredEventsPage = () => {
    setPageNumber(4);
  }
  const setTeamOutputPage = () => {
    setPageNumber(5);
  }
  const setAccountPage = () => {
    setPageNumber(6);
  }

  const handleReturnToHome = () => {
    setPageNumber(0);
  }

  //Page Rendering
  const renderSubPage = () => {
    switch(pageNumber) {
      case 0:
        return <Events />;
      case 1:
        return <Participants />;
      case 2:
        return <Facilitators />;
      case 3:
        return <SuperAdmins />;
      case 4:
        return <ExpiredEvents isFacilitator={false} />;
      case 5:
        return <TeamOutput />;
      case 6:
        return <Account cbCancel={handleReturnToHome} />;
      default:
        return <div></div>;
    }
  }

  return (
    <>
      <AdminHeader  selected={pageNumber}
                    expiredEventCount={expiredEventCount}
                    cbEvents={setEventsPage}
                    cbParticipants={setParticipantsPage}
                    cbFacilitators={setFacilitatorsPage}
                    cbSuperAdmins={setSuperAdminsPage}
                    cbExpiredEvents={setExpiredEventsPage}
                    cbTeamOutput={setTeamOutputPage}
                    cbAccount={setAccountPage} />

      <div className="container-superadminhome">
        {renderSubPage()}
      </div>
    </>
  );
}

export default SuperAdminHome;
