import React from 'react';

import '../styles/Footer.css';

function Footer({openLegal, openAccount}) {
  const year = (new Date()).getFullYear();

  return (
    <>
      <footer>
        <div className="footer">
          <div>
            <p>Copyright © 2007-{year} Pursuit Development Labs Inc. All Rights Reserved.</p>
          </div>
          <div>
            <p className="container-link">
              <a href="/">Home</a> | <a onClick={() => openLegal()}>Our Mutual Agreement</a> | <a onClick={() => openAccount()}>Account</a>
            </p>
          </div>
          <div>
            <p>Contact us: info@pursuitinc.com</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
