const getPageName = (n) => {
  let out = "";
  if (n == 0) {
    out = "";//"Legal";
  } else {
    out = "";//"Pre-work";
  }

  return out;
}

const getPageInfo = (n) => {
  let out = "";
  switch(n) {
    case 0:
      out = "Our Mutual Agreement";
      break;
    case 1:
      out = "Introduction";
      break;
    case 2:
      out = "Step 1 (of 4)";
      break;
    case 3:
      out = "Step 1 (of 4)";
      break;
    case 4:
      out = "Step 2 (of 4)";
      break;
    case 5:
      out = "Step 3 (of 4)";
      break;
    case 6:
      out = "Step 4 (of 4)";
      break;
    case 7:
      out = "";
      break;
    default:
      out = "";
  }

  return out;
}

module.exports = {
  getPageName,
  getPageInfo
};
