import React from 'react';

import {GreenCheckmark, RedX} from './Checkmark.js';

import '../styles/components/InfoPopup.css';

function InfoPopup({message, success}) {
  return (
    <>
      <div className="container-infopopupfloat">
        <div className="container-infopopup">
          <div className={success ? "success" : "failed"}>{message}</div>
          <div>{success ? <GreenCheckmark /> : <RedX />}</div>
        </div>
      </div>
    </>
  )
}

export default InfoPopup;
