import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const API_ROUTE = process.env.REACT_APP_API_URL;

const LOGIN_CHECK_TIME = process.env.REACT_APP_LOGIN_CHECK_TIME;

const ProtectedRoute = ({ children }) => {
  const { login, logout, isLoggedIn, setFirstLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  let checkLoginInterval = null;
  // if (!loggedIn) {
  //   return <Navigate to="/login" replace />;
  // }

  const checkLogin = () => {
    axios.post(`${API_ROUTE}/auth/loggedIn`, {}, {
      withCredentials: true
    }).then((res) => {
      // console.log(res);
      // console.log(isLoggedIn());
      // alert('Checking login');
      if (res.data.firstLogin) {
        setFirstLogin(true);
      }
      if (res.data.valid) {
        login(res.data.accessLevel);
      } else {
        clearInterval(checkLoginInterval);
        checkLoginInterval = null;
        logout();
      }
    }).catch((err) => {
      console.log(err);
      clearInterval(checkLoginInterval);
      checkLoginInterval = null;
      logout();
    });
  };

  useEffect(() => {
    checkLogin();
    checkLoginInterval = setInterval(checkLogin, LOGIN_CHECK_TIME);
  }, [])

  return children;
};

export default ProtectedRoute;
