import React, {useState} from 'react';
import axios from 'axios';

import AddParticipant from './AddParticipant.js';
import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/admin/AddEvent.css';

const DATE_FIELDS = ['eventDate', 'accessClose'];

const DEFAULT_EVENT_DATA = {
  eventName: '',
  eventDate: new Date(),
  accessClose: new Date((new Date()).setMonth((new Date()).getMonth()+1)),
  facilitator: '',
  eventType: 'Reg',
  sessionAccess: 'Pre-work'
};

const API_ROUTE = process.env.REACT_APP_API_URL;

function AddEvent({cbClose}) {
  const [eventData, setEventData] = useState(DEFAULT_EVENT_DATA);
  const [participants, setParticipants] = useState([]);
  const [addingParticipant, setAddingParticipant] = useState(false);
  const [infoPopup, setInfoPopup] = useState(null);

  const handleEventDataChange = (event) => {
    let newEventData = {...eventData};
    if (DATE_FIELDS.includes(event.target.id)) {
      if (event.target.id == 'eventDate') {
         let eventDate = new Date(event.target.value);
         newEventData['accessClose'] = new Date(eventDate.setMonth(eventDate.getMonth()+1));
      }
      newEventData[event.target.id] = new Date(event.target.value);
    } else {
      newEventData[event.target.id] = event.target.value;
    }
    setEventData(newEventData);
  }

  const addParticipant = () => {
    setAddingParticipant(true);
  }

  const closeAddParticipant = () => {
    setAddingParticipant(false);
  }

  const pushParticipant = (participant) => {
    let newParticipants = [...participants];
    newParticipants.push(participant);
    setParticipants(newParticipants);
  };

  const saveData = () => {
    let participantUIDs = [];
    for (let p of participants) {
      participantUIDs.push(p.uid);
    }

    const eventObj = {
      eventName: eventData.eventName,
      eventDate: eventData.eventDate,
      accessClose: eventData.accessClose,
      eventType: eventData.eventType,
      sessionAccess: eventData.sessionAccess,
      participants: participantUIDs
    };

    axios.post(`${API_ROUTE}/event/create`, eventObj, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      console.log(res);
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        cbClose();
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  return (
    <>
      <div>
        {addingParticipant && (
          <AddParticipant cbClose={closeAddParticipant}
                          cbAddParticipant={pushParticipant}
                          participantsMenu={false} />
        )}
        {infoPopup && (infoPopup)}
      </div>
      <div className="container-addeventfloat">
        <div className="container-addevent">
          <div className="title underline">Add Event</div>

          <div className="container-addeventcontent">
            <div className="container-addeventinfo">
              <div className="subtitle">Event Info</div>
              <div className="container-input">
                <div className="label">Name: </div>
                <input  id="eventName"
                        className="input-addevent"
                        type="text"
                        value={eventData.eventName}
                        onChange={handleEventDataChange}
                        placeholder="Enter the Event Name" />
              </div>
              <div className="container-input">
                <div className="label">Event Date: </div>
                <input  id="eventDate"
                        className="input-addevent"
                        type="date"
                        value={eventData.eventDate.toISOString().split('T')[0]}
                        onChange={handleEventDataChange} />
              </div>
              <div className="container-input">
                <div className="label">Access Close: </div>
                <input  id="accessClose"
                        className="input-addevent"
                        type="date"
                        value={eventData.accessClose.toISOString().split('T')[0]}
                        onChange={handleEventDataChange} />
              </div>
              <div className="container-input">
                <div className="label">Event Rate: </div>
                <select id="eventType"
                        className="input-addevent"
                        onChange={handleEventDataChange}
                        required>
                  <option value="Reg">Regular</option>
                  <option value="Non-reg">Discount</option>
                </select>
              </div>
              <div className="container-input">
                <div className="label">Session Access: </div>
                <select id="sessionAccess"
                        className="input-addevent"
                        onChange={handleEventDataChange}
                        required>
                  <option value="Pre-work">Pre-work</option>
                  <option value="Live">Live</option>
                </select>
              </div>
            </div>

            <div className="container-participants">
              <div className="subtitle">Participant List</div>
              {participants.map((participant, i) =>
                <div className="container-participantlist">
                  <div className="text-participant">{participant.forename} {participant.surname}</div>
                  <button className="button-participant">Remove</button>
                </div>
              )}
              <button className="add-participant" onClick={() => addParticipant()}>Add Participant</button>
            </div>
          </div>

          <div className="container-buttons">
            <button className="button save" onClick={saveData}>Save</button>
            <button className="button cancel" onClick={cbClose}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEvent;
