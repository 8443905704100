import React, {useState, useEffect} from 'react';
import axios from 'axios';

import InfoPopup from '../../components/InfoPopup.js';
import AddParticipant from './AddParticipant.js';
import EditParticipant from './EditParticipant.js';

import '../../styles/admin/Participants.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function Participants() {
  const [allParticipants, setAllParticipants] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [uploadedParticipants, setUploadedParticipants] = useState([]);
  const [addingParticipant, setAddingParticipant] = useState(false);
  const [editParticipantIndex, setEditParticipantIndex] = useState(-1);
  const [editUploadedParticipantIndex, setEditUploadedParticipantIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState("");
  const [infoPopup, setInfoPopup] = useState(null);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  }

  const addParticipant = () => {
    setAddingParticipant(true);
  };

  const closeAddParticipant = () => {
    setAddingParticipant(false);
  };

  const editParticipant = (index) => {
    setEditParticipantIndex(index);
  };

  const closeEditParticipant = () => {
    setEditParticipantIndex(-1);
  };

  const editUploadedParticipant = (index) => {
    setEditUploadedParticipantIndex(index);
  };

  const closeEditUploadedParticipant = () => {
    setEditUploadedParticipantIndex(-1);
  };

  useEffect(() => {
    let newParticipants = [...allParticipants];
    newParticipants = newParticipants.filter((participant) => {
      let forenameValid = participant.forename.toLowerCase().includes(searchQuery.toLowerCase());
      let surnameValid = participant.surname.toLowerCase().includes(searchQuery.toLowerCase());
      return forenameValid || surnameValid;
    });
    setParticipants(newParticipants);
  }, [searchQuery]);

  useEffect(() => {
    axios.get(`${API_ROUTE}/user/participants`, {
      withCredentials: true
    }).then((res) => {
      setParticipants(res.data.users);
      setAllParticipants(res.data.users);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    })
  }, [editParticipantIndex, addingParticipant]);

  useEffect(() => {
    axios.get(`${API_ROUTE}/user/participants_uploaded`, {
      withCredentials: true
    }).then((res) => {
      setUploadedParticipants(res.data.users);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  }, [editUploadedParticipantIndex]);

  return (
    <>
      <div>
        {editParticipantIndex >= 0 && (
          <EditParticipant  cbClose={closeEditParticipant}
                            participantData={participants[editParticipantIndex]} />
        )}
        {editUploadedParticipantIndex >= 0 && (
          <EditParticipant  cbClose={closeEditUploadedParticipant}
                            participantData={uploadedParticipants[editUploadedParticipantIndex]} />
        )}
        {addingParticipant && (
          <AddParticipant cbClose={closeAddParticipant} participantsMenu={true} />
        )}
        {infoPopup && (infoPopup)}
      </div>
      <div className="container-participants">
        <div className="container-topbar">
          <div className="container-search">
            <div className="grid-title">Search: </div>
            <input  id="input-search"
                    className="input-search"
                    type="text"
                    value={searchQuery}
                    onChange={handleInputChange}
                    placeholder="Search for a participant" />
          </div>
          <div>
            <button onClick={addParticipant}>Add Participant</button>
          </div>
        </div>

        <div className="container-participantlist">
          <div className="grid-item grid-title">
            <div>First Name</div>
          </div>
          <div className="grid-item grid-title">
            <div>Last Name</div>
          </div>
          <div className="grid-item grid-title">
            <div>Email</div>
          </div>
          <div className="grid-item grid-title">
          </div>

          {allParticipants.length == 0 && (
            <>
              <div className="grid-row"><br /></div>
              <div className="grid-row">No participants found. Please add a participant with the 'Add Participant' button or by doing it directly from an event on the 'Active Events' tab.</div>
            </>
          )}

          {participants.map((participant, i) =>
            <>
              <div className="grid-item">
                <div className="grid-text">{participant.forename}</div>
              </div>
              <div className="grid-item">
                <div className="grid-text">{participant.surname}</div>
              </div>
              <div className="grid-item">
                <div className="grid-text">{participant.email}</div>
              </div>
              <div className="grid-item">
                <button onClick={() => editParticipant(i)}>Edit</button>
              </div>
            </>
          )}
        </div>
        {uploadedParticipants.length > 0 && (
          <>
            <div className="title">Uploaded Participants</div>

            <div className="container-participantlist">
              <div className="grid-item grid-title">
                <div>Forename</div>
              </div>
              <div className="grid-item grid-title">
                <div>Surname</div>
              </div>
              <div className="grid-item grid-title">
                <div>Email</div>
              </div>
              <div className="grid-item grid-title">
              </div>

              {uploadedParticipants.map((participant, i) =>
                <>
                  <div className="grid-item">
                    <div className="grid-text">{participant.forename}</div>
                  </div>
                  <div className="grid-item">
                    <div className="grid-text">{participant.surname}</div>
                  </div>
                  <div className="grid-item">
                    <div className="grid-text">{participant.email}</div>
                  </div>
                  <div className="grid-item">
                    <button onClick={() => editUploadedParticipant(i)}>Edit</button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Participants;
