import React, {useContext, useState} from 'react';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import {AuthContext} from '../context/AuthContext.js';

import SessionHeader from '../components/SessionHeader.js';

import '../styles/Register.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function Register() {
  const [userAccessLevel, setUserAccessLevel] = useState(0);
  const [forename, setForename] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {login, logout, accessLevel, loggedIn} = useContext(AuthContext);

  const navigate = useNavigate();

  const handleAccessLevelChange = (event) => {
    setUserAccessLevel(event.target.value);
  };

  const handleForenameChange = (event) => {
    setForename(event.target.value);
  };

  const handleSurnameChange = (event) => {
    setSurname(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRegister = () => {
    let registerObj = {
      accessLevel: userAccessLevel,
      forename: forename,
      surname: surname,
      email: email,
      password: password
    };

    axios.post(`${API_ROUTE}/auth/register`, registerObj, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      console.log(res.data);
      navigate('/');
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <>
      <SessionHeader pageName="" pageInfo="" />

      <div className="container-register">
        <div className="title">Register A User</div>
        <div className="container-inputs">
          <div className="label">Access Level: </div>
          <select id={`register-accesslevel`}
                  className="input-register"
                  onChange={handleAccessLevelChange}
                  required>
            <option value={0}>Participant</option>
            {accessLevel == 2 && (<option value={1}>Guide</option>)}
            {accessLevel == 2 && (<option value={2}>Super-Admin</option>)}
          </select>
          <div className="label">Forename: </div>
          <input  id={`register-forename`}
                  className="input-register"
                  type="text"
                  value={forename}
                  onChange={handleForenameChange}
                  required />
          <div className="label">Surname: </div>
          <input  id={`register-surname`}
                  className="input-register"
                  type="text"
                  value={surname}
                  onChange={handleSurnameChange}
                  required />
          <div className="label">Email: </div>
          <input  id={`register-email`}
                  className="input-register"
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  required />
          <div className="label">Password: </div>
          <input  id={`register-password`}
                  className="input-register"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required />
        </div>

        <div className="container-buttons">
          <button onClick={handleRegister}>Register</button>
        </div>
      </div>
    </>
  );
}

export default Register;
