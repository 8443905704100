const getPageName = (n) => {
  let out = "";
  if (n == 0) {
    out = "";
  }

  return out;
}

const getPageInfo = (n) => {
  let out = "";
  switch(n) {
    case 0:
      out = "Our Mutual Agreement";
      break;
    case 1:
      out = "Edit Your Values";
      break;
    case 2:
      out = "Categorize Your Values";
      break;
    default:
      out = "";
  }

  return out;
}

module.exports = {
  getPageName,
  getPageInfo
};
