import React, {useContext, useState, useEffect} from 'react';
import axios from 'axios';

import '../styles/ResetPassword.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState(0);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSendEmail = () => {
    const emailData = {
      email: email
    };
    axios.post(`${API_ROUTE}/auth/reset_password_email`, emailData, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      if (res.data.success) {
        setEmailStatus(1);
      } else {
        setEmailStatus(2);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <>
      <div className="container-resetpassword">
        {emailStatus > 0 ?
          <>
            {emailStatus == 1 && (
              <div className="label">
                An email was sent with a link to reset your password.
              </div>
            )}
            {emailStatus == 2 && (
              <div className="label">
                No user found with the given email. Contact your facilitator for more information.
              </div>
            )}
          </>
          :
          <>
            <div className="title">Forgot Your Password?</div>
            <div className="container-inputs">
              <div className="label">Email: </div>
              <input  id={`email`}
              className="input-register"
              type="text"
              value={email}
              onChange={handleEmailChange}
              required />
            </div>

            <div className="container-buttons">
              <button onClick={handleSendEmail}>Send Email</button>
            </div>
          </>
        }
      </div>
    </>
  );
}

export default ForgotPassword;
