import React, {useState, useEffect} from 'react';

import {Checkmark} from './Checkmark.js';

import '../styles/components/ValueSelector.css';

function ValueSelector({index, text, cbText, selected, cbSelected}) {
  const handleCheckedChange = (event) => {
    cbSelected(index);
  };

  const handleValueChange = (event) => {
    cbText(event.target.value, index);
  };

  return (
    <>
      <div className="container-valueselector">
        <div className="select-valueselector" onClick={handleCheckedChange}>
          {selected && (<div className="container-checkmark"><Checkmark /></div>)}
        </div>
        <input  className="text-valueselector"
                type="text"
                value={text}
                onChange={handleValueChange}
                placeholder="Enter a Value" />
      </div>
    </>
  );
}

export default ValueSelector;
