import React, {useState, useEffect} from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
// import { HTML5Backend } from 'react-dnd-html5-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import axios from 'axios';

import ValueDefinition from '../../components/ValueDefinition.js';
import DraggableValue from '../../components/DraggableValue.js';
import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/live/Editing.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function Editing({cbPrevious, cbNext, cbToPrework, user}) {
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [values, setValues] = useState([]);
  const [definitions, setDefinitions] = useState([]);
  const [originalIndexes, setOriginalIndexes] = useState([]);
  const [percentLived, setPercentLived] = useState([]);
  const [infoPopup, setInfoPopup] = useState(null);

  const moveItem = (fromI, toI) => {
    const updatedValues = [...values];
    const [removedValue] = updatedValues.splice(fromI, 1);
    updatedValues.splice(toI, 0, removedValue);
    // const fromValue = updatedValues[fromI];
    // const toValue = updatedValues[toI];
    // updatedValues[fromI] = toValue;
    // updatedValues[toI] = fromValue;
    setValues(updatedValues);

    const updatedDefinitions = [...definitions];
    const [removedDefinition] = updatedDefinitions.splice(fromI, 1);
    updatedDefinitions.splice(toI, 0, removedDefinition);
    // const fromDefinition = updatedDefinitions[fromI];
    // const toDefinition = updatedDefinitions[toI];
    // updatedDefinitions[fromI] = toDefinition;
    // updatedDefinitions[toI] = fromDefinition;
    setDefinitions(updatedDefinitions);

    const updatedOriginalIndexes = [...originalIndexes];
    const [removedOriginalIndex] = updatedOriginalIndexes.splice(fromI, 1);
    updatedOriginalIndexes.splice(toI, 0, removedOriginalIndex);
    // const fromOriginalIndex = updatedOriginalIndexes[fromI];
    // const toOriginalIndex = updatedOriginalIndexes[toI];
    // updatedOriginalIndexes[fromI] = toOriginalIndex;
    // updatedOriginalIndexes[toI] = fromOriginalIndex;
    setOriginalIndexes(updatedOriginalIndexes);

    const updatedPercentLived = [...percentLived];
    const [removedPercentLived] = updatedPercentLived.splice(fromI, 1);
    updatedPercentLived.splice(toI, 0, removedPercentLived);
    // const fromPercentLived = updatedPercentLived[fromI];
    // const toPercentLived = updatedPercentLived[toI];
    // updatedPercentLived[fromI] = toPercentLived;
    // updatedPercentLived[toI] = fromPercentLived;
    setPercentLived(updatedPercentLived);
  };

  const setValue = (i, value) => {
    const newValues = [...values];
    newValues[i] = value;
    setValues(newValues);
  };

  const setDefinition = (i, definition) => {
    const newDefinitions = [...definitions];
    newDefinitions[i] = definition;
    setDefinitions(newDefinitions);
  };

  const setLive = (i, live) => {
    const re = /^[0-9\b]+$/;
    if (live === '' || re.test(live)) {
      const newLived = [...percentLived];
        if (parseInt(live) <= 100 || live == '') {
          newLived[i] = live;
        }
      setPercentLived(newLived);
    }
  };

  const saveData = (next) => {
    let dataUpdate = {
      values: Array.from(values, (val, i) => {return {value: val, originalIndex: originalIndexes[i]}}),
      descriptions: definitions,
      percentLived: percentLived
    };

    if (user) {
      dataUpdate.uid = user;
    }
    const url = user ? `${API_ROUTE}/response/participant_byid` : `${API_ROUTE}/response/participant`;

    axios.put(url, dataUpdate, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      // console.log(res);
      // alert('Successfully saved!');
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        if (next) {
          next();
        }
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  useEffect(() => {
    const url = user ? `${API_ROUTE}/response/participant_byid` : `${API_ROUTE}/response/participant`;
    const options = user ? {
      withCredentials: true,
      params: new URLSearchParams({prework: false, uid: user})
    } : {
      withCredentials: true,
      params: new URLSearchParams({prework: false})
    };

    axios.get(url, options).then((res) => {
      let newValues = res.data.values;
      let newDefinitions = res.data.descriptions;
      let newOriginalIndexes = res.data.originalIndexes;
      let newPercentLived = res.data.percentLived;

      setValues(newValues);
      setDefinitions(newDefinitions);
      setOriginalIndexes(newOriginalIndexes);
      setPercentLived(newPercentLived);
    }).catch((err) => {
      cbToPrework();
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });

    const eventUrl = user ? `${API_ROUTE}/event/participant_event_byid` : `${API_ROUTE}/event/participant_event`;
    const eventOptions = user ? {
      withCredentials: true,
      params: new URLSearchParams({uid: user})
    } : {
      withCredentials: true
    };

    axios.get(eventUrl, eventOptions).then((res) => {
      let newEventName = res.data.event.eventName;
      let newEventDate = new Date(res.data.event.eventDate);

      setEventName(newEventName);
      setEventDate(newEventDate);
    });
  }, []);

  return (
    <>
      {infoPopup && (infoPopup)}
      <div className="container-editing">
        <div className="container-info">
          <p className="live-instruction">Edit your values labels, definitions, % Lived, or re-rank your values from 1-10.</p>
          <p className="event-info">[{eventName}/{eventDate.toDateString()}]</p>
        </div>

        <DndProvider options={HTML5toTouch}>
          <div className="container-valuedefinitions">
            {values.map((value, i) =>
              <DraggableValue
                key={i}
                id={i}
                index={i}
                moveItem={moveItem}
              >
                <ValueDefinition  key={i}
                                  index={i}
                                  value={value}
                                  definition={definitions[i]}
                                  lived={percentLived[i]}
                                  setValue={setValue}
                                  setDefinition={setDefinition}
                                  setLived={setLive}
                                  draggable={true}
                                  bold={true} />
              </DraggableValue>
            )}
          </div>
        </DndProvider>

        <div className="container-buttons">
          <button className="button-live" onClick={() => saveData(cbPrevious)}>Save & Previous</button>
          <button className="button-live" onClick={() => saveData()}>Save</button>
          <button className="button-live" onClick={() => saveData(cbNext)}>Save & Next</button>
        </div>
      </div>
    </>
  );
}

export default Editing;
