import React, {useContext, useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {AuthContext} from '../context/AuthContext.js';

import SessionHeader from '../components/SessionHeader.js';

//Subpage imports
import Legal from '../subpages/prework/Legal.js';
import Introduction from '../subpages/prework/Introduction.js';
import IntroductionIdentifying from '../subpages/prework/IntroductionIdentifying.js';
import Identifying from '../subpages/prework/Identifying.js';
import Defining from '../subpages/prework/Defining.js';
import Ranking from '../subpages/prework/Ranking.js';
import Lived from '../subpages/prework/Lived.js';
import Completion from '../subpages/prework/Completion.js';

//Helper imports
import {getPageName, getPageInfo} from '../helpers/PreworkHelpers.js';

//Static imports
import '../styles/Prework.css';

//Prework constants
const PAGE_COUNT = 8;

function Prework() {
  const {logout} = useContext(AuthContext);
  const {uid} = useParams();
  const [pageNumber, setPageNumber] = useState(0);

  let navigate = useNavigate();

  //Handle input functions
  const handleNextPage = () => {
    if (pageNumber + 1 < PAGE_COUNT) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber - 1 >= 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleReturnHome = () => {
    navigate('/');
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (uid && pageNumber < 2) {
      setPageNumber(2);
    }
  }, [uid]);

  //Rendering helper functions
  const renderSubPage = () => {
    switch(pageNumber) {
      case 0:
        return <Legal cbAgree={(e) => handleNextPage()} />;
      case 1:
        return <Introduction  cbPrevious={() => handlePreviousPage()}
                              cbNext={() => handleNextPage()} />;
      case 2:
        return <IntroductionIdentifying cbPrevious={() => handlePreviousPage()}
                                        cbNext={() => handleNextPage()} />;
      case 3:
        return <Identifying cbPrevious={() => handlePreviousPage()}
                            cbNext={() => handleNextPage()}
                            user={uid}/>;
      case 4:
        return <Defining  cbPrevious={() => handlePreviousPage()}
                          cbNext={() => handleNextPage()}
                          user={uid}/>;
      case 5:
        return <Ranking cbPrevious={() => handlePreviousPage()}
                        cbNext={() => handleNextPage()}
                        user={uid}/>;
      case 6:
        return <Lived cbPrevious={() => handlePreviousPage()}
                      cbNext={() => handleNextPage()}
                      user={uid}/>;
      case 7:
        return  <Completion cbPrevious={() => handlePreviousPage()}
                            cbFinish={uid ? () => handleBack() : () => handleLogout()}
                            user={uid}/>;
      default:
        return <div></div>;
    }
  };

  return (
    <>
      <SessionHeader pageName={getPageName(pageNumber)} pageInfo={getPageInfo(pageNumber)} />

      <div className="container-prework">
        {renderSubPage()}
      </div>
    </>
  );
}

export default Prework;
