import React, {useContext, useRef, useState, useEffect} from 'react';
import { AuthProvider } from './context/AuthContext.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute.js';

import Account from './subpages/Account.js';
import Legal from './components/Legal.js';

import Home from './pages/Home.js';
import Login from './pages/Login.js';
import ResetPassword from './pages/ResetPassword.js';
import ForgotPassword from './pages/ForgotPassword.js';
import Register from './pages/Register.js';
import Prework from './pages/Prework.js';
import Live from './pages/Live.js';
import Print from './pages/Print.js';

import Footer from './components/Footer.js';

import './App.css';

function App() {
  const [account, setAccount] = useState(false);
  const [legal, setLegal] = useState(false);

  const handleAccountPopup = () => {
    setAccount(true);
  };

  const handleCloseAccount = () => {
    setAccount(false);
  };

  const handleLegalPopup = () => {
    setLegal(true);
  };

  const handleCloseLegal = () => {
    setLegal(false);
  };

  useEffect(() => {
    const footerHeight = document.querySelector('footer').clientHeight;
    document.documentElement.style.setProperty('--footer-height', `${footerHeight}px`);
  }, []);

  return (
    <div className="App">
      <div className="container-content">
        <Router>
          <AuthProvider>
            <div>
              {account && (
                <div className="container-float">
                  <Account cbCancel={handleCloseAccount} />
                </div>
              )}
              {legal && (
                <Legal cbClose={handleCloseLegal} />
              )}
            </div>
            <Routes>
              <Route path="/" exact element={<ProtectedRoute><Home /></ProtectedRoute>} />
              <Route path="/login" exact element={<Login />} />
              <Route path="/reset_password/:lid" exact element={<ResetPassword />} />
              <Route path="/forgot_password" exact element={<ForgotPassword />} />
              <Route path="/register" exact element={<ProtectedRoute><Register /></ProtectedRoute>} />
              <Route path="/prework/:uid?" exact element={<ProtectedRoute><Prework /></ProtectedRoute>} />
              <Route path="/live/:uid?" exact element={<ProtectedRoute><Live /></ProtectedRoute>} />
              <Route path="/print/:uid/:page" exact element={<Print />} />
            </Routes>
          </AuthProvider>
        </Router>
      </div>
      <Footer openLegal={handleLegalPopup} openAccount={handleAccountPopup}/>
    </div>
  );
}

export default App;
