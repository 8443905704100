import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = {
  LIST_ITEM: 'listItem'
};

function DraggableValue({id, index, moveItem, children, isDrop}) {
  const [, ref] = useDrag({
    type: ItemTypes.LIST_ITEM,
    item: { id, index },
  });

  let useDropOpts = {accept: ItemTypes.LIST_ITEM};
  if (isDrop) {
    useDropOpts.drop = (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    }
  } else {
    useDropOpts.hover = (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    }
  }

  const [, drop] = useDrop(useDropOpts);

  return (
    <div ref={(node) => ref(drop(node))} style={{height: "100%"}}>
      {children}
    </div>
  );
}

export default DraggableValue;
