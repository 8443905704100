import React from 'react';

const Checkmark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      fill="none"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="checkmark"
      width="22"
      height="22"
    >
      <path d="M18 7L9 16l-4-4" />
    </svg>
  );
};

const GreenCheckmark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      fill="none"
      stroke="#00AA00"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="checkmark"
      width="22"
      height="22"
    >
      <path d="M18 7L9 16l-4-4" />
    </svg>
  );
};

const RedX = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <line x1="0" y1="0" x2="22" y2="22" stroke="#FF0000" strokeWidth="2" />
      <line x1="0" y1="22" x2="22" y2="0" stroke="#FF0000" strokeWidth="2" />
    </svg>
  );
};

export {
  Checkmark,
  GreenCheckmark,
  RedX
};
