import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/account/FacilitatorAccount.css';

const FIELD_NAMES = ["First Name", "Last Name", "Email", "New Password", "Confirm New Password"];
let mockFieldValues = ["", "", "", "", ""];

const API_ROUTE = process.env.REACT_APP_API_URL;

function FacilitatorAccount({cbCancel, cbLogout, cbSave}) {
  const navigate = useNavigate();
  const [fieldValues, setFieldValues] = useState(mockFieldValues);
  const [portalLink, setPortalLink] = useState(null);
  const [emailBody, setEmailBody] = useState("");
  const [closing, setClosing] = useState("");
  const [infoPopup, setInfoPopup] = useState(null);

  const handleInputChange = (event) => {
    let id = event.target.id.split('-');
    id = parseInt(id[id.length-1]);

    let newFieldValues = [...fieldValues];
    newFieldValues[id] = event.target.value;
    setFieldValues(newFieldValues);
  };

  const handleEmailBodyChange = (event) => {
    setEmailBody(event.target.value);
  };

  const handleClosingChange = (event) => {
    setClosing(event.target.value);
  };

  const handlePortalLink = (event) => {
    if (portalLink) {
      window.open(portalLink, '_blank');
    }
  };

  const saveData = () => {
    if (fieldValues[3] != fieldValues[4]) {
      const newInfoPopup = <InfoPopup message={"Passwords do not match"}
                                      success={false} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
      // alert('Passwords do not match');
      return;
    }

    const userData = {
      forename: fieldValues[0],
      surname: fieldValues[1],
      email: fieldValues[2],
      password: fieldValues[3]
    };

    axios.put(`${API_ROUTE}/user/updateself`, userData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        cbCancel();
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  const saveTemplateData = () => {
    const updateData = {
      body: emailBody,
      closing: closing
    };

    axios.put(`${API_ROUTE}/user/email_template`, updateData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  useEffect(() => {
    axios.get(`${API_ROUTE}/user/info`, {
      withCredentials: true
    }).then((res) => {
      const newFieldValues = [...fieldValues];
      newFieldValues[0] = res.data.user.forename;
      newFieldValues[1] = res.data.user.surname;
      newFieldValues[2] = res.data.user.email;
      setFieldValues(newFieldValues);
      setPortalLink(res.data.user.portal_link);
    }).catch((err) => {
      console.log(err);
    });

    axios.get(`${API_ROUTE}/user/email_template`, {
      withCredentials: true
    }).then((res) => {
      setEmailBody(res.data.template.body);
      setClosing(res.data.template.closing);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <>
      {infoPopup && (infoPopup)}
      <div className="container-facilitatoraccount">
        <div>
          <div className="container-input">
            <form id="user-form" onSubmit={() => saveData()}>
              {FIELD_NAMES.map((fieldName, i) =>
                <div className="grid-item">
                  <div className="left">{fieldName}</div>
                  <input  id={`fa-input-${i}`}
                          className="right text-facilitatoraccount"
                          type={fieldName.includes('Password') ? "password" : "text"}
                          pattern={fieldName.includes('Password') ? "(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" : ".*"}
                          title={fieldName.includes('Password') ? "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" : ""}
                          value={fieldValues[i]}
                          onChange={handleInputChange}
                          placeholder={`Enter Your ${fieldName.replace('Confirm ', '')}`} />
                </div>
              )}
            </form>
          </div>

          <div className="container-buttons">
            <button type="submit" form="user-form" className="button-account">Save</button>
            <button className="button-account" onClick={cbLogout}>Logout</button>
            <button className="button-account" onClick={cbCancel}>Cancel</button>
          </div>
          {portalLink && (
            <div className="container-portal-link">
              <button className="button-account" onClick={handlePortalLink}>Your Portal</button>
            </div>
          )}
        </div>
        <div className="container-emailtemplate">
          <div className="title">Email Template</div>

          <div className="container-emailtext">
            <div className="info">Hi [Participant Name Inserted Here],</div>
            <textarea className=""
                      value={emailBody}
                      onChange={handleEmailBodyChange}
                      placeholder="Name and message to participant"
                      rows={7} />
            <div className="info">Values Pre-work Link: [Link Inserted Here]</div>
            <textarea className=""
                      value={closing}
                      onChange={handleClosingChange}
                      placeholder="Your closing message"
                      rows={4} />
          </div>

          <div className="container-buttons">
            <div></div>
            <button className="button-account" onClick={saveTemplateData}>Save Template</button>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FacilitatorAccount;
