import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import JSZip from 'jszip';
import * as ReactDOM from 'react-dom';
import { saveAs } from 'file-saver'

import '../../styles/admin/ExpiredEvents.css';

import Confirmation from '../../components/Confirmation.js';
import Loading from '../../components/Loading.js';
import InfoPopup from '../../components/InfoPopup.js';

let VISIBLE_FIELDS = ['eventName', 'eventDate', 'accessClose', 'facilitator', 'participantCount', 'eventType'];

const API_ROUTE = process.env.REACT_APP_API_URL;

function ExpiredEvents({isFacilitator}) {
  const [events, setEvents] = useState([]);
  const [visibleEvents, setVisibleEvents] = useState([]);
  const [eventsPendingRemoval, setEventsPendingRemoval] = useState([]);
  const [originalIndexes, setOriginalIndexes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleting, setDeleting] = useState(-1);
  const [uploadedEvents, setUploadedEvents] = useState([]);
  const [visibleUploadedEvents, setVisibleUploadedEvents] = useState([]);
  const [eventIndex, setEventIndex] = useState(-1);
  const [uploading, setUploading] = useState(false);
  const [uploadedDeleting, setUploadedDeleting] = useState(-1);
  const [infoPopup, setInfoPopup] = useState(null);
  const [loading, setLoading] = useState(false);

  const valToString = (val) => {
    if (val instanceof Date) {
      return val.toDateString();
    } else if (val instanceof Object) {
      return `${val.forename} ${val.surname}`;
    } else {
      return val.toString();
    }
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const downloadFiles = (event, uploaded) => {
    setLoading(true);
    axios.get(`${API_ROUTE}/event/event_file`, {
      withCredentials: true,
      params: new URLSearchParams({eid: event.eid, uploaded: uploaded}),
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'arraybuffer'
    }).then((res) => {
      const zip = new Blob([res.data], { type: 'application/zip' });

      const contentDisposition = res.headers.get('Content-Disposition');
      const filename = contentDisposition.split('filename=')[1].replace(/\"/g, '');
      saveAs(zip, filename);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
      setLoading(false);
    });
  };

  const deleteEvent = () => {
    const curEventData = {
      eid: events[deleting].eid,
      uploaded: false
    };

    axios.delete(`${API_ROUTE}/event/delete`, {
      data: curEventData,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      console.log(res);
      const newInfoPopup = <InfoPopup message={'Successfully Closed Event'}
                                      success={true} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        cancelCloseEvent();
      }, process.env.REACT_APP_INFO_TIMEOUT);
      // alert('Event deleted successfully');
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  const closeEvent = (index) => {
    setDeleting(index);
  };

  const cancelCloseEvent = () => {
    setDeleting(-1);
  };

  useEffect(() => {
    let newVisibleEvents = [];
    let newOriginalIndexes = [];
    let count = 0;
    for (let e of events) {
      let cur = {};
      for (let key of Object.keys(e)) {
        if (VISIBLE_FIELDS.includes(key)) {
          cur[key] = e[key];
        }
        if (key == 'participants') {
          cur['participantCount'] = e.participants.length;
        }
      }
      newVisibleEvents.push(cur);
      newOriginalIndexes.push(count);
      count += 1;
    }
    setVisibleEvents(newVisibleEvents);
    setOriginalIndexes(newOriginalIndexes);
  }, [events]);

  useEffect(() => {
    let newVisibleEvents = [];
    let newOriginalIndexes = [];
    let count = 0;
    for (let e of events) {
      if (!e.eventName.toLowerCase().includes(searchQuery.toLowerCase())) {
        continue;
      }
      let cur = {};
      for (let key of Object.keys(e)) {
        if (VISIBLE_FIELDS.includes(key)) {
          cur[key] = e[key];
        }
      }
      newVisibleEvents.push(cur);
      newOriginalIndexes.push(count);
      count += 1;
    }
    setVisibleEvents(newVisibleEvents);
    setOriginalIndexes(newOriginalIndexes);
  }, [searchQuery]);

  useEffect(() => {
    axios.get(`${API_ROUTE}/event/all_expired`, {
      withCredentials: true
    }).then((res) => {
      let newEvents = [];
      for (let e of res.data.events) {
        let cur = e;
        cur.eventDate = new Date(cur.eventDate);
        cur.accessClose = new Date(cur.accessClose);
        newEvents.push(cur);
      }

      setEvents(newEvents);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  }, [deleting]);

  useEffect(() => {
    axios.get(`${API_ROUTE}/event/pending_removal`, {
      withCredentials: true
    }).then((res) => {
      let tmpEventsPendingRemoval = [];
      for  (let e of res.data.events) {
        let cur = e;
        cur.eventDate = new Date(cur.eventDate);
        cur.accessClose = new Date(cur.accessClose);
        tmpEventsPendingRemoval.push(cur);
      }

      let newEventsPendingRemoval = [];
      for (let e of tmpEventsPendingRemoval) {
        let cur = {};
        for (let key of Object.keys(e)) {
          if (VISIBLE_FIELDS.includes(key)) {
            if (e[key] instanceof Date) {
              let newDate = e[key];
              newDate.setTime(newDate.getTime() + (8 * 60*60*1000));
              cur[key] = newDate;
            } else {
              cur[key] = e[key];
            }
          }
          if (key == 'participants') {
            cur['participantCount'] = e.participants.length;
          }
        }
        newEventsPendingRemoval.push(cur);
      }

      setEventsPendingRemoval(newEventsPendingRemoval);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  }, []);

  return (
    <>
      <div>
        {deleting >= 0 && (
          <Confirmation cbDelete={deleteEvent}
                        cbCancel={cancelCloseEvent}
                        message={`Please ensure you have downloaded the event files before deletion. This action will remove the event and all participants from the system. It cannot be undone, however, if you have downloaded the event files you will be able to upload the event for editing.`}
                        buttonName={"Delete"} />
        )}
        {loading && (
          <Loading />
        )}
        {infoPopup && (infoPopup)}
      </div>
      <div className="container-expiredevents">
        <div className="container-topbar">
          <div className="container-search">
            <div className="">Search: </div>
            <input  id="input-search"
                    className="input-search"
                    type="text"
                    value={searchQuery}
                    onChange={handleInputChange}
                    placeholder="Search for an event" />
          </div>
        </div>
        <div className="container-eventlist">
          <div className="grid-item grid-title">
            <div>Name</div>
          </div>
          <div className="grid-item grid-title">
            <div>Event Date</div>
          </div>
          <div className="grid-item grid-title">
            <div>Access Close</div>
          </div>
          <div className="grid-item grid-title">
            <div>Facilitator</div>
          </div>
          <div className="grid-item grid-title">
            <div>Participants</div>
          </div>
          <div className="grid-item grid-title">
            <div>Event Rate</div>
          </div>
          <div className="grid-item grid-title">
          </div>
          <div className="grid-item grid-title">
          </div>

          {events.length == 0 && (
            <>
              <div className="grid-row"><br /></div>
              <div className="grid-row">You're all caught up! No closed events found.</div>
            </>
          )}

          {visibleEvents.map((eventData, i) =>
            <>
              {Object.keys(eventData).map((key, i) =>
                <div className="grid-item">
                  <div className="grid-text">{valToString(eventData[key])}</div>
                </div>
              )}
              <div className="grid-item">
                <button onClick={() => downloadFiles(events[originalIndexes[i]], false)}>Download Files</button>
              </div>
              <div className="grid-item">
                <button onClick={() => closeEvent(originalIndexes[i])}>Remove Event</button>
              </div>
            </>
          )}
        </div>
        {eventsPendingRemoval.length > 0 && (
          <>
            <div className="title">Events Pending Removal</div>
            <div className="container-eventlist">
              <div className="grid-item grid-title">
                <div>Name</div>
              </div>
              <div className="grid-item grid-title">
                <div>Event Date</div>
              </div>
              <div className="grid-item grid-title">
                <div>Access Close</div>
              </div>
              <div className="grid-item grid-title">
                <div>Facilitator</div>
              </div>
              <div className="grid-item grid-title">
                <div>Participants</div>
              </div>
              <div className="grid-item grid-title">
                <div>Event Type</div>
              </div>
              <div className="grid-item grid-title">
              </div>
              <div className="grid-item grid-title">
              </div>

              {eventsPendingRemoval.map((eventData, i) =>
                <>
                  {Object.keys(eventData).map((key, i) =>
                    <div className="grid-item">
                      <div className="grid-text">{valToString(eventData[key])}</div>
                    </div>
                  )}
                  <div className="grid-item">
                  </div>
                  <div className="grid-item">
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ExpiredEvents;
