import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

//Styles
import '../styles/components/AdminHeader.css';

function AdminHeader({eventLive, selected, expiredEventCount, cbEvents, cbParticipants, cbFacilitators, cbSuperAdmins, cbExpiredEvents, cbTeamOutput, cbAccount}) {
  const navigate = useNavigate();

  const handleEventClick = () => {
    if (eventLive) {
      navigate('/live');
    } else if (eventLive == false) {
      navigate('/prework');
    }
  };

  const renderFacilitatorHeader = () => {
    return (
      <ul>
        <li>
          <button className={selected==0 && "selected"} onClick={cbEvents}>Active Events</button>
        </li>
        <li>
          <button className={selected==1 && "selected"} onClick={cbParticipants}>Participants</button>
        </li>
        <li>
          <button className={selected==2 && "selected"} onClick={cbExpiredEvents}>
            Removed Events
            {expiredEventCount > 0 && (<span className="count-badge">{expiredEventCount}</span>)}
          </button>
        </li>
        <li>
          <button className={selected==3 && "selected"} onClick={cbTeamOutput}>Team Output</button>
        </li>
        <li>
          <button className={selected==4 && "selected"} onClick={cbAccount}>Account</button>
        </li>
        {eventLive != null && (
          <li>
            <button onClick={handleEventClick}>My Personal Event</button>
          </li>
        )}
      </ul>
    );
  };

  const renderSuperAdminHeader = () => {
    return (
      <ul>
        <li>
          <button className={selected==0 && "selected"} onClick={cbEvents}>Active Events</button>
        </li>
        <li>
          <button className={selected==1 && "selected"} onClick={cbParticipants}>Participants</button>
        </li>
        <li>
          <button className={selected==2 && "selected"} onClick={cbFacilitators}>Guides</button>
        </li>
        <li>
          <button className={selected==3 && "selected"} onClick={cbSuperAdmins}>Super-Admins</button>
        </li>
        <li>
          <button className={selected==4 && "selected"} onClick={cbExpiredEvents}>
            Removed Events
            {expiredEventCount > 0 && (<span className="count-badge">{expiredEventCount}</span>)}
          </button>
        </li>
        <li>
          <button className={selected==5 && "selected"} onClick={cbTeamOutput}>Team Output</button>
        </li>
        <li>
          <button className={selected==6 && "selected"} onClick={cbAccount}>Account</button>
        </li>
      </ul>
    );
  };

  const renderHeaderList = () => {
    if (cbFacilitators == undefined) {
      return renderFacilitatorHeader();
    } else {
      return renderSuperAdminHeader();
    }
  };

  return (
    <header>
      <div className="header-admin">
        <div>
          <a href="/">
            <img className="logo" src="/images/PursuitLogo.png" alt="Pursuit Inc." />
          </a>
        </div>
        <div>
          {renderHeaderList()}
        </div>
      </div>
    </header>
  );
}

export default AdminHeader;
