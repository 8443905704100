import React, {useState, useEffect} from 'react';
import axios from 'axios';

import InfoPopup from '../../components/InfoPopup.js';
import AddFacilitator from './AddFacilitator.js';
import EditFacilitator from './EditFacilitator.js';

import '../../styles/admin/Facilitators.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function Facilitators() {
  const [allFacilitators, setAllFacilitators] = useState([]);
  const [facilitators, setFacilitators] = useState([]);
  const [addingFacilitator, setAddingFacilitator] = useState(false);
  const [editFacilitatorIndex, setEditFacilitatorIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState("");
  const [infoPopup, setInfoPopup] = useState(null);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  }

  const addFacilitator = () => {
    setAddingFacilitator(true);
  };

  const closeAddFacilitator = () => {
    setAddingFacilitator(false);
  };

  const editFacilitator = (index) => {
    setEditFacilitatorIndex(index);
  }

  const closeEditFacilitator = () => {
    setEditFacilitatorIndex(-1);
  }

  useEffect(() => {
    let newFacilitators = [...allFacilitators];
    newFacilitators = newFacilitators.filter((facilitator) => {
      let forenameValid = facilitator.forename.toLowerCase().includes(searchQuery.toLowerCase());
      let surnameValid = facilitator.surname.toLowerCase().includes(searchQuery.toLowerCase());
      return forenameValid || surnameValid;
    });
    setFacilitators(newFacilitators);
  }, [searchQuery]);

  useEffect(() => {
    axios.get(`${API_ROUTE}/user/facilitators`, {
      withCredentials: true
    }).then((res) => {
      setFacilitators(res.data.users);
      setAllFacilitators(res.data.users);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    })
  }, [editFacilitatorIndex, addingFacilitator]);

  return (
    <>
      <div>
        {editFacilitatorIndex >= 0 && (
          <EditFacilitator  cbClose={closeEditFacilitator}
                            facilitatorData={facilitators[editFacilitatorIndex]} />
        )}
        {addingFacilitator && (
          <AddFacilitator cbClose={closeAddFacilitator} />
        )}
        {infoPopup && (infoPopup)}
      </div>
      <div className="container-facilitators">
        <div className="container-topbar">
          <div className="container-search">
            <div className="grid-title">Search: </div>
            <input  id="input-search"
                    className="input-search"
                    type="text"
                    value={searchQuery}
                    onChange={handleInputChange}
                    placeholder="Search for a guide" />
          </div>
          <div>
            <button onClick={addFacilitator}>Add Guide</button>
          </div>
        </div>

        <div className="container-facilitatorlist">
          <div className="grid-item grid-title">
            <div>First Name</div>
          </div>
          <div className="grid-item grid-title">
            <div>Last Name</div>
          </div>
          <div className="grid-item grid-title">
            <div>Email</div>
          </div>
          <div className="grid-item grid-title">
          </div>

          {allFacilitators.length == 0 && (
            <>
              <div className="grid-row"><br /></div>
              <div className="grid-row">No guides found. Please add a guide with the 'Add Guide' button.</div>
            </>
          )}

          {facilitators.map((facilitator, i) =>
            <>
              <div className="grid-item">
                <div className="grid-text">{facilitator.forename}</div>
              </div>
              <div className="grid-item">
                <div className="grid-text">{facilitator.surname}</div>
              </div>
              <div className="grid-item">
                <div className="grid-text">{facilitator.email}</div>
              </div>
              <div className="grid-item">
                <button onClick={() => editFacilitator(i)}>Edit</button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Facilitators;
