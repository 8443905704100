import React, {useState, useEffect} from 'react';
import axios from 'axios';

import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/admin/AddParticipant.css';

const FIELD_NAMES = ["First Name", "Last Name", "Email"];
const FIELD_NAMES_EXTRA = ["First Name", "Last Name", "Email", "Password"];

const API_ROUTE = process.env.REACT_APP_API_URL;

function AddParticipant({cbClose, cbAddParticipant, participantsMenu}) {
  const [fieldValues, setFieldValues] = useState(["", "", "", ""]);
  const [existingParticipants, setExistingParticipants] = useState([]);
  const [existingFacilitators, setExistingFacilitators] = useState([]);
  const [existingUsers, setExistingUsers] = useState([]);
  const [selectedExistingUser, setSelectedExistingUser] = useState(-1);
  const [infoPopup, setInfoPopup] = useState(null);

  // const FIELDS = participantsMenu ? FIELD_NAMES_EXTRA : FIELD_NAMES;
  const FIELDS = FIELD_NAMES_EXTRA;

  const handleInputChange = (event) => {
    let index = event.target.id.split('-');
    index = parseInt(index[index.length-1]);

    let newFieldValues = [...fieldValues];
    newFieldValues[index] = event.target.value;
    setFieldValues(newFieldValues);
  }

  const handleExistingUserChange = (event) => {
    setSelectedExistingUser(event.target.value);
  }

  const addParticipant = () => {
    if (selectedExistingUser >= 0) {
      cbAddParticipant(existingUsers[selectedExistingUser].user);
      cbClose();
    } else {
      const registerObj = {
        accessLevel: 0,
        forename: fieldValues[0],
        surname: fieldValues[1],
        email: fieldValues[2],
        password: fieldValues[3]
      };

      axios.post(`${API_ROUTE}/auth/register`, registerObj, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        const newInfoPopup = <InfoPopup message={res.data.message}
                                        success={res.data.success} />;
        setInfoPopup(newInfoPopup);
        setTimeout(() => {
          setInfoPopup(null);
          cbClose();
        }, process.env.REACT_APP_INFO_TIMEOUT);
        // alert('Participant Added Successfully');
        cbAddParticipant(res.data.user);
      }).catch((err) => {
        console.log(err);
        // if (Object.keys(err).includes('response')) {
        //   const newInfoPopup = <InfoPopup message={err.response.message}
        //                                   success={false} />;
        //   setInfoPopup(newInfoPopup);
        //   setTimeout(() => {
        //     setInfoPopup(null);
        //   }, process.env.REACT_APP_INFO_TIMEOUT);
        //   return;
        // }
        const newInfoPopup = <InfoPopup message={err.response.data.message}
                                        success={err.response.data.success} />;
        setInfoPopup(newInfoPopup);
        setTimeout(() => {
          setInfoPopup(null);
        }, process.env.REACT_APP_INFO_TIMEOUT);
      });
    }
  };

  const saveData = () => {
    let registerObj = {
      accessLevel: 0,
      forename: fieldValues[0],
      surname: fieldValues[1],
      email: fieldValues[2],
      password: fieldValues[3],
      setupLink: !participantsMenu
    };

    axios.post(`${API_ROUTE}/auth/register`, registerObj, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        cbClose();
      }, process.env.REACT_APP_INFO_TIMEOUT);
      // alert('Participant Added Successfully');
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  useEffect(() => {
    let newExistingUsers = [];
    for (let p of existingParticipants) {
      newExistingUsers.push({accessLevel: 0, user: p});
    }
    for (let f of existingFacilitators) {
      newExistingUsers.push({accessLevel: 1, user: f});
    }
    console.log(newExistingUsers);
    setExistingUsers(newExistingUsers);
  }, [existingParticipants, existingFacilitators]);

  useEffect(() => {
    axios.get(`${API_ROUTE}/user/participants`, {
      withCredentials: true
    }).then((res) => {
      setExistingParticipants(res.data.users);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });

    axios.get(`${API_ROUTE}/user/facilitators`, {
      withCredentials: true
    }).then((res) => {
      setExistingFacilitators(res.data.users);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    })
  }, []);

  return (
    <>
      {infoPopup && (infoPopup)}
      <div className="container-addparticipantfloat">
        <div className="container-addparticipant">
          <div className="title underline">Add Participant</div>
          <div className="container-input">
            {FIELDS.map((fieldName, i) =>
              <div className="grid-item">
                {!(!participantsMenu && i == 3) && (
                  <>
                    <div className="left">{fieldName}</div>
                    <input  id={`pa-input-${i}`}
                            className="right text-participantaccount"
                            type="text"
                            value={fieldValues[i]}
                            onChange={handleInputChange}
                            placeholder={`Enter The ${fieldName.replace('Confirm ', '')}`} />
                  </>
                )}
              </div>
            )}
          </div>

          {!participantsMenu && (
            <>
              <div className="separator">OR</div>
              <div className="container-input">
                <div className="grid-item">
                  <div className="left">Select An Existing Participant</div>
                  <select id="exisitingParticipant"
                          className="input-addparticipant"
                          onChange={handleExistingUserChange}>
                    <option value={-1}>--- Select A Participant ---</option>
                    {existingUsers.map((participant, i) =>
                      <option value={i} disabled={participant.user.event != null}>{participant.user.forename} {participant.user.surname} - {participant.user.email} {participant.accessLevel==1 && (`(GUIDE)`)}</option>
                    )}
                  </select>
                </div>
              </div>
            </>
          )}

          <div className="container-buttons">
            <button className="button save" onClick={cbAddParticipant ? addParticipant : saveData}>
              {participantsMenu ? "Save" : "Create/Add"}
            </button>
            <button className="button cancel" onClick={cbClose}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddParticipant;
