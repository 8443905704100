import React from 'react';

import '../styles/components/CookieConfirmation.css';

function CookieConfirmation({cbClose}) {
  return (
    <>
      <div className="container-float">
        <div className="container-cookies">
          <p>This site only uses essential cookies to support authentication/login, no other cookies are being used.</p>
          <div className="container-buttons">
            <button className="button-confirm" onClick={cbClose}>Confirm</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CookieConfirmation;
