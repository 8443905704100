import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

import SessionHeader from '../../components/SessionHeader.js';

import Account from '../Account.js';

import '../../styles/home/ParticipantHome.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function ParticipantHome() {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const [eventLive, setEventLive] = useState(false);

  const handleReturnHome = () => {
    setPageNumber(0);
  };

  const handleParticipant = () => {
    if (eventLive) {
      navigate('/live');
    } else {
      navigate('/prework');
    }
  };

  const renderSubPage = () => {
    switch (pageNumber) {
      case 0:
        return (
          <>
            <div className="container-participanthome">
              <p className="bold italics">Welcome to The Pursuit Values System&trade; experience!</p>
              <p>Through this experience, you will become more aware of who you are uniquely to more consciously create a life and work of joy, meaning, and valuable impact.</p>
              <div>
                {!eventLive && (<>
                  <p>The exercise on the following pages will help you complete your ‘Pre-work’ in preparation for your upcoming session where you will have a unique opportunity to Uncover Your Values System&trade;.</p>
                  <br />
                  <p className="bold">To begin your Pre-work please click ‘Next’.</p>
                  <br />
                </>)}
                {eventLive && (<p>By way of the following exercise you will be supported to Uncover Your Values System&trade;.</p>)}
              </div>
              <p className="italics">You may click on ‘Account’ at the bottom of the page to change your password or personal information.</p>
              {!eventLive && (<br />)}
              <p>We truly hope you enjoy this experience of personal learning and discovery!</p>
            </div>
            <div className="container-participant-buttons">
              <div></div>
              <button className="button-participant" onClick={handleParticipant}>Next</button>
            </div>
          </>
        );
      case 1:
        return <Account cbCancel={handleReturnHome} />
      default:
        return <div></div>
    }
  };

  useEffect(() => {
    axios.get(`${API_ROUTE}/event/participant_event`, {
      withCredentials: true
    }).then((res) => {
      let sessionAccess = res.data.event.sessionAccess;
      setEventLive(sessionAccess=="Live");
    }).catch((err) => {
      console.log(err);
    });
  }, [])

  return (
    <>
      <SessionHeader pageName="" pageInfo="Welcome" />

      {renderSubPage()}
    </>
  );
}

export default ParticipantHome;
