import React, {useState, useEffect} from 'react';
import { DndProvider} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';

import ValueRepresentation from '../../components/ValueRepresentation.js';
import DraggableValue from '../../components/DraggableValue.js';
import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/live/Revealing.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function Revealing({cbNext, cbPrevious, user}) {
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [values, setValues] = useState([]);
  const [definitions, setDefinitions] = useState([]);
  const [originalIndexes, setOriginalIndexes] = useState([]);
  const [percentLived, setPercentLived] = useState([]);
  const [infoPopup, setInfoPopup] = useState(null);

  const moveItem = (fromI, toI) => {
    const updatedValues = [...values];
    const fromValue = updatedValues[fromI];
    const toValue = updatedValues[toI];
    updatedValues[fromI] = toValue;
    updatedValues[toI] = fromValue;
    setValues(updatedValues);

    const updatedDefinitions = [...definitions];
    const fromDefinition = updatedDefinitions[fromI];
    const toDefinition = updatedDefinitions[toI];
    updatedDefinitions[fromI] = toDefinition;
    updatedDefinitions[toI] = fromDefinition;
    setDefinitions(updatedDefinitions);

    const updatedOriginalIndexes = [...originalIndexes];
    const fromOriginalIndex = updatedOriginalIndexes[fromI];
    const toOriginalIndex = updatedOriginalIndexes[toI];
    updatedOriginalIndexes[fromI] = toOriginalIndex;
    updatedOriginalIndexes[toI] = fromOriginalIndex;
    setOriginalIndexes(updatedOriginalIndexes);

    const updatedPercentLived = [...percentLived];
    const fromPercentLived = updatedPercentLived[fromI];
    const toPercentLived = updatedPercentLived[toI];
    updatedPercentLived[fromI] = toPercentLived;
    updatedPercentLived[toI] = fromPercentLived;
    setPercentLived(updatedPercentLived);
  };

  const saveData = (next) => {
    let dataUpdate = {
      values: Array.from(values, (val, i) => {return {value: val, originalIndex: originalIndexes[i]}}),
      descriptions: definitions,
      percentLived: percentLived
    };

    if (user) {
      dataUpdate.uid = user;
    }
    const url = user ? `${API_ROUTE}/response/participant_byid` : `${API_ROUTE}/response/participant`;

    axios.put(url, dataUpdate, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      // console.log(res);
      // alert('Successfully saved!');
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        if (next) {
          next();
        }
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    const url = user ? `${API_ROUTE}/response/participant_byid` : `${API_ROUTE}/response/participant`;
    const options = user ? {
      withCredentials: true,
      params: new URLSearchParams({prework: true, uid: user})
    } : {
      withCredentials: true,
      params: new URLSearchParams({prework: true})
    };

    axios.get(url, options).then((res) => {
      let newValues = res.data.values;
      let newDefinitions = res.data.descriptions;
      let newOriginalIndexes = res.data.originalIndexes;
      let newPercentLived = res.data.percentLived;

      setValues(newValues);
      setDefinitions(newDefinitions);
      setOriginalIndexes(newOriginalIndexes);
      setPercentLived(newPercentLived);
    });

    const eventUrl = user ? `${API_ROUTE}/event/participant_event_byid` : `${API_ROUTE}/event/participant_event`;
    const eventOptions = user ? {
      withCredentials: true,
      params: new URLSearchParams({uid: user})
    } : {
      withCredentials: true
    };

    axios.get(eventUrl, eventOptions).then((res) => {
      let newEventName = res.data.event.eventName;
      let newEventDate = new Date(res.data.event.eventDate);

      setEventName(newEventName);
      setEventDate(newEventDate);
    });
  }, []);

  return (
    <>
      {infoPopup && (infoPopup)}
      <div className="container-revealing">
        <div className="container-info">
          <p className="live-instruction">Click and drag your Values below to place them in the desired categories.</p>
          <p className="event-info">[{eventName}/{eventDate.toDateString()}]</p>
        </div>


        <DndProvider backend={HTML5Backend}>
          <div className="container-valuedescriptions">
            <div className="grid-item center text">
              <div className="title">Driver Value</div>
              <div className="subtitle">
                “What is critical for me to have present and honoured in my environment & relationships to connect & flow.”
              </div>
            </div>
            <div className="grid-item center">
              <DraggableValue key={0} id={0} index={0} moveItem={moveItem} isDrop={true}>
                <ValueRepresentation index={0} value={values[0]} definition={definitions[0]} />
              </DraggableValue>
            </div>

            <div className="grid-item left text">
              <div className="title">Go To Values</div>
              <div className="subtitle">
                "What I bring & leverage with others to move things forward."
              </div>
            </div>
            <div className="grid-item right text">
              <div className="title">Accelerator Values</div>
              <div className="subtitle">
                "What I leverage to resource myself & others to get unstuck & move forward again."
              </div>
            </div>

            <div className="grid-item left">
              <DraggableValue key={1} id={1} index={1} moveItem={moveItem} isDrop={true}>
                <ValueRepresentation index={1} value={values[1]} definition={definitions[1]} />
              </DraggableValue>
            </div>
            <div className="grid-item right">
              <DraggableValue key={5} id={5} index={5} moveItem={moveItem} isDrop={true}>
                <ValueRepresentation index={5} value={values[5]} definition={definitions[5]} />
              </DraggableValue>
            </div>

            <div className="grid-item left">
              <DraggableValue key={2} id={2} index={2} moveItem={moveItem} isDrop={true}>
                <ValueRepresentation index={2} value={values[2]} definition={definitions[2]} />
              </DraggableValue>
            </div>
            <div className="grid-item right">
              <DraggableValue key={6} id={6} index={6} moveItem={moveItem} isDrop={true}>
                <ValueRepresentation index={6} value={values[6]} definition={definitions[6]} />
              </DraggableValue>
            </div>

            <div className="grid-item left">
              <DraggableValue key={3} id={3} index={3} moveItem={moveItem} isDrop={true}>
                <ValueRepresentation index={3} value={values[3]} definition={definitions[3]} />
              </DraggableValue>
            </div>
            <div className="grid-item right">
              <DraggableValue key={7} id={7} index={7} moveItem={moveItem} isDrop={true}>
                <ValueRepresentation index={7} value={values[7]} definition={definitions[7]} />
              </DraggableValue>
            </div>

            <div className="grid-item left">
              <DraggableValue key={4} id={4} index={4} moveItem={moveItem} isDrop={true}>
                <ValueRepresentation index={4} value={values[4]} definition={definitions[4]} />
              </DraggableValue>
            </div>
            <div className="grid-item right">
              <DraggableValue key={8} id={8} index={8} moveItem={moveItem} isDrop={true}>
                <ValueRepresentation index={8} value={values[8]} definition={definitions[8]} />
              </DraggableValue>
            </div>

            <div className="grid-item center text">
              <div className="title">Result Value</div>
              <div className="subtitle">
                "What I deeply desire as a meaningful outcome for myself & others."
              </div>
            </div>
            <div className="grid-item center">
              <DraggableValue key={9} id={9} index={9} moveItem={moveItem} isDrop={true}>
                <ValueRepresentation index={9} value={values[9]} definition={definitions[9]} />
              </DraggableValue>
            </div>
          </div>
        </DndProvider>

        <div className="container-buttons">
          <button className="button-live" onClick={() => saveData(cbPrevious)}>Save & Previous</button>
          <button className="button-live" onClick={() => saveData()}>Save</button>
          <button className="button-live" onClick={() => saveData(cbNext)}>Save & Next</button>
        </div>
      </div>
    </>
  );
}

export default Revealing;
