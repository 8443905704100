import React, {useEffect} from 'react';

import '../styles/components/ValueDefinition.css';

function ValueDefinition({index, value, definition, lived, setValue, setDefinition, setLived, draggable, downloading, bold}) {
  const handleValueChange = (event) => {
    // setValueText(event.target.value);
    setValue(index, event.target.value);
  };

  const handleDefinitionChange = (event) => {
    // setDefinitionText(event.target.value);
    setDefinition(index, event.target.value);
    event.target.style.height = 'inherit';
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  const handleLivedChange = (event) => {
    setLived(index, event.target.value);
  };

  useEffect(() => {
    // let valueElems = document.getElementsByClassName("text");
    // for (let value of valueElems) {
    //   value.style.height = `${value.scrollHeight}px`
    // }
  }, []);

  return (
    <>
      <div className={`container-valuedefinition ${draggable ? "dashed" : "solid"}`}>
        <div className="grid-item rank">{index+1}.</div>
        <div className="grid-item value">
          {(downloading) ?
            <div className="bold">{value}</div>
          :
            <textarea className="text bold"
                      type="text"
                      value={value}
                      onChange={handleValueChange}
                      placeholder="Enter a Value"
                      rows={1} />
          }
        </div>
        <div className="grid-item lived">
          {lived != undefined && (
            <div className={downloading ? "removeoutline" : ""}>
              <p className="input-lived-label">% Lived</p>
              <input  className="input-lived"
                      type="text"
                      value={lived}
                      onChange={handleLivedChange} />
            </div>
          )}
        </div>
        <div className="grid-item definition">
          {downloading ?
            <div className="definition-downloading">{definition}</div>
          :
            <textarea className="textarea"
                      value={definition}
                      onChange={handleDefinitionChange}
                      placeholder="Describe this value"
                      rows={4}
                      cols={35} />
          }
        </div>
      </div>
    </>
  );
}

export default ValueDefinition;
