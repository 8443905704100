import React from 'react';

import '../styles/components/ValueRepresentation.css';

function ValueRepresentation({index, value, definition}) {
  return (
    <>
      <div className="container-valuerepresentation">
        <div className="grid-item-valuerepresentation">
          <p className="bold">{index+1}.</p>
        </div>
        <div className="grid-item-valuerepresentation">
          <p className="value-text bold">{value}</p>
        </div>
        <div className="grid-item-valuerepresentation description">
          <p>{definition}</p>
        </div>
      </div>
    </>
  );
}

export default ValueRepresentation;
