import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import { saveAs } from 'file-saver'

import AddParticipant from './AddParticipant.js';
import Confirmation from '../../components/Confirmation.js';
import ParticipantProgress from '../../components/ParticipantProgress.js';
import EmailConfirmation from '../../components/EmailConfirmation.js';
import InfoPopup from '../../components/InfoPopup.js';
import Loading from '../../components/Loading.js';

import '../../styles/admin/EditEvent.css';

const DATE_FIELDS = ['eventDate', 'accessClose'];

const API_ROUTE = process.env.REACT_APP_API_URL;

function EditEvent({cbClose, currentEvent}) {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState(currentEvent);
  const [participants, setParticipants] = useState([]);
  const [addingParticipant, setAddingParticipant] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [resendEmailComponent, setResendEmailComponent] = useState(null);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [infoPopup, setInfoPopup] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEventDataChange = (event) => {
    let newEventData = {...eventData};
    if (DATE_FIELDS.includes(event.target.id)) {
      newEventData[event.target.id] = new Date(event.target.value);
    } else {
      newEventData[event.target.id] = event.target.value;
    }
    setEventData(newEventData);
  }

  const addParticipant = () => {
    setAddingParticipant(true);
  };

  const closeAddParticipant = () => {
    setAddingParticipant(false);
  };

  const viewParticipant = (participant) => {
    if (eventData.sessionAccess == 'Pre-work') {
      navigate(`/prework/${participant.uid}`);
    } else {
      navigate(`/live/${participant.uid}`);
    }
  };

  const removeParticipant = (participant) => {
    axios.put(`${API_ROUTE}/event/remove_participant`, {
      uid: participant.uid,
      eid: eventData.eid
    }, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      console.log(res);
      let newEventData = {...eventData};
      newEventData.participants = res.data.participants;
      setEventData(newEventData);
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  const pushParticipant = (participant) => {
    axios.post(`${API_ROUTE}/event/add_participant`, {
      uid: participant.uid,
      eid: eventData.eid
    }, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  const downloadOutput = (participant) => {
    const uid = participant.uid;
    const filename = `${participant.forename}_${participant.surname}-ValuesSystem.pdf`;

    setLoading(true);
    axios.get(`${API_ROUTE}/event/event_output`, {
      withCredentials: true,
      params: new URLSearchParams({uid: uid, page: eventData.sessionAccess == 'Pre-work' ? 'prework' : 'live'}),
      responseType: 'arraybuffer'
    }).then((res) => {
      const pdf = new Blob([res.data], { type: 'application/pdf' });
      saveAs(pdf, filename);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  const sendEmails = () => {
    axios.post(`${API_ROUTE}/event/send_emails`, {eid: eventData.eid}, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      console.log(res);
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  const sendEmail = (uid) => {
    setResendEmailComponent(null);
    setSendingEmail(true);
    axios.post(`${API_ROUTE}/event/send_email`, {uid: uid}, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      console.log(res);
      setSendingEmail(false);
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      setSendingEmail(false);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    })
  };

  const confirmSendEmail = (participant, sent) => {
    const message = sent ? `Are you sure you want to resend the logon email to ${participant.forename} ${participant.surname} at ${participant.email}?`
                         : `Please confirm you want to send a logon email to ${participant.forename} ${participant.surname} at ${participant.email}.`;
    const newResendEmailComponent = <EmailConfirmation  cbConfirm={() => sendEmail(participant.uid)}
                                                        cbCancel={() => setResendEmailComponent(null)}
                                                        message={message} />;
    setResendEmailComponent(newResendEmailComponent);
  };

  const saveData = () => {
    const eventUpdate = {
      eid: eventData.eid,
      eventName: eventData.eventName,
      eventDate: eventData.eventDate,
      accessClose: eventData.accessClose,
      eventType: eventData.eventType,
      sessionAccess: eventData.sessionAccess
    };

    axios.put(`${API_ROUTE}/event/update`, eventUpdate, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        cbClose();
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    })
  }

  const deleteData = () => {
    const curEventData = {
      eid: eventData.eid
    };

    axios.put(`${API_ROUTE}/event/facilitatorclose`, curEventData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      console.log(res);
      setDeleting(false);
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        cbClose();
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  const confirmDelete = () => {
    setDeleting(true);
  };

  const cancelDelete = () => {
    setDeleting(false);
  };

  const confirmationMessage = `Are you sure you want to remove event ${eventData.eventName}? This will move the event to the 'Removed Events' page and you and all participants will no longer be able to view their responses.`;

  const refreshEvent = () => {
    axios.get(`${API_ROUTE}/event`, {
      params: new URLSearchParams({eid: eventData.eid}),
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      let newEventData = res.data.event;
      newEventData.eventDate = new Date(newEventData.eventDate);
      newEventData.accessClose = new Date(newEventData.accessClose);
      setEventData(newEventData);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  useEffect(() => {
    axios.get(`${API_ROUTE}/event/participants`, {
      params: new URLSearchParams({eid: eventData.eid}),
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      setParticipants(res.data.users);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  }, [addingParticipant, eventData]);

  return (
    <>
      <div>
        {addingParticipant && (
          <AddParticipant cbClose={closeAddParticipant}
                          cbAddParticipant={pushParticipant}
                          participantsMenu={false} />
        )}
        {loading && (
          <Loading />
        )}
        {resendEmailComponent && (resendEmailComponent)}
        {infoPopup && (infoPopup)}
      </div>
      <div className="container-editeventfloat">
        <div>
          {deleting && (
            <Confirmation cbDelete={deleteData}
                          cbCancel={cancelDelete}
                          message={confirmationMessage}
                          buttonName={"Remove Event"} />
          )}
        </div>
        <div className="container-editevent">
          <div className="title underline">Event Dashboard</div>
          <div className="container-refresh">
            <img  className="refresh"
                  src="/images/refresh-icon.png"
                  alt="Refresh"
                  title="Refresh"
                  tabindex="0"
                  onClick={refreshEvent}/>
          </div>

          <div className="container-editeventcontent">
            <div className="container-editeventinfo">
              <div className="subtitle">Event Info</div>
              <div className="container-input">
                <div className="label">Name: </div>
                <input  id="eventName"
                        className="input-editevent"
                        type="text"
                        value={eventData.eventName}
                        onChange={handleEventDataChange}
                        placeholder="Enter the Event Name" />
              </div>
              <div className="container-input">
                <div className="label">Event Date: </div>
                <input  id="eventDate"
                        className="input-editevent"
                        type="date"
                        value={eventData.eventDate.toISOString().split('T')[0]}
                        onChange={handleEventDataChange} />
              </div>
              <div className="container-input">
                <div className="label">Access Close: </div>
                <input  id="accessClose"
                        className="input-editevent"
                        type="date"
                        value={eventData.accessClose.toISOString().split('T')[0]}
                        onChange={handleEventDataChange} />
              </div>
              <div className="container-input">
                <div className="label">Event Rate: </div>
                <select id="eventType"
                        className="input-editevent"
                        onChange={handleEventDataChange}
                        value={eventData.eventType}
                        required>
                  <option value="Reg">Regular</option>
                  <option value="Non-reg">Discount</option>
                </select>
              </div>
              <div className="container-input">
                <div className="label">Session Access: </div>
                <select id="sessionAccess"
                        className="input-editevent"
                        onChange={handleEventDataChange}
                        value={eventData.sessionAccess}
                        required>
                  <option value="Pre-work">Pre-work</option>
                  <option value="Live">Live</option>
                </select>
              </div>
              <div className="container-buttons-input">
                <button className="button close-event" onClick={confirmDelete}>Remove Event</button>
              </div>
            </div>

            <div className="container-eventparticipants">
              <div className="container-participantlist">
                <div className="subtitle">Participant List</div>
                <div></div>
                <div className="subsubtitle">Prework</div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              {participants.map((participant, i) =>
                <div className="container-participantlist">
                  <div className="text-participant">{participant.forename} {participant.surname}</div>
                  <div className="container-icon">
                    <img  className="icon"
                          src={eventData.emailsSent[i] ? "/images/email-sent-icon.png" : "/images/email-icon.png"}
                          alt={eventData.emailsSent[i] ? "Y" : "N"}
                          title={eventData.emailsSent[i] ? "Login email has been sent" : "Login email not sent"}
                          onClick={() => confirmSendEmail(participant, eventData.emailsSent[i])}/>
                  </div>
                  <div style={{padding: "0 10px"}}>
                    <ParticipantProgress preworkProgress={participant.responseCompleted} />
                  </div>
                  <button className="button-participant" onClick={() => viewParticipant(participants[i])}>View</button>
                  <button className="button-participant" onClick={() => downloadOutput(participants[i])} disabled={(participant.responseCompleted.reduce((pS, a)=>pS+a, 0) == 0)}>Print</button>
                  <button className="button-participant" onClick={() => removeParticipant(participants[i])}>Remove</button>
                </div>
              )}
              <div className="container-buttons">
                <button className="add-participant" onClick={addParticipant}>Add Participant</button>
                <button className="send-emails" onClick={sendEmails}>Send Setup Emails</button>
              </div>
            </div>
          </div>

          <div className="container-buttons">
            <button className="button save" onClick={saveData}>Save</button>
            <button className="button cancel" onClick={cbClose}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditEvent;
