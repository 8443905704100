import React, {useState, useEffect} from 'react';
import axios from 'axios';

import ValueSelector from '../../components/ValueSelector.js';
import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/prework/Identifying.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function Identifying({cbPrevious, cbNext, user}) {
  const [values, setValues] = useState([]);
  const [selected, setSelected] = useState([]);
  const [infoPopup, setInfoPopup] = useState(null);

  const handleValueChange = (value, i) => {
    let newValues = [...values];
    newValues[i] = value;
    setValues(newValues);
  };

  const handleSelectedChange = (i) => {
    let newSelected = [...selected];
    newSelected[i] = !newSelected[i];
    setSelected(newSelected);
  };

  const saveData = (next) => {
    let valuesData = [];
    for (let i = 0; i < selected.length; i++) {
      if (selected[i]) {
        valuesData.push({
          value: values[i],
          originalIndex: i
        });
      }
    }
    if (valuesData.length != 10) {
      // alert('Please choose exactly 10 values');
      const newInfoPopup = <InfoPopup message={'Please choose exactly 10 values'}
                                      success={false} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
      return;
    }

    let valuesUpdate = {
      values: valuesData,
      descriptions: Array.from({length: valuesData.length}, () => ""),
      percentLived: Array.from({length: valuesData.length}, () => 100),
      completedMark: 0
    };

    if (user) {
      valuesUpdate.uid = user;
    }
    const url = user ? `${API_ROUTE}/response/participant_byid` : `${API_ROUTE}/response/participant`;

    axios.put(url, valuesUpdate, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      // console.log(res);
      // alert('Successfully saved!');
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        if (next) {
          next();
        }
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  useEffect(() => {
    const url = user ? `${API_ROUTE}/response/participant_byid` : `${API_ROUTE}/response/participant`;
    const options = user ? {
      withCredentials: true,
      params: new URLSearchParams({prework: true, uid: user})
    } : {
      withCredentials: true,
      params: new URLSearchParams({prework: true})
    };

    axios.get(url, options).then((res) => {
      let newValues = res.data.originalValues;
      let newSelected = Array.from({length: newValues.length}, () => false);
      for (let i = 0; i < res.data.originalIndexes.length; i++) {
        let index = res.data.originalIndexes[i];
        newValues[index] = res.data.values[i];
        newSelected[index] = true;
      }

      setValues(newValues);
      setSelected(newSelected);
    }).catch((err) => {
      setValues(err.response.data.originalValues);
      let newSelected = Array.from({length: err.response.data.originalValues.length}, () => false);
      setSelected(newSelected);
    });
  }, []);

  return (
    <>
      {infoPopup && (infoPopup)}
      <div className="container-identifying">
        <div className="container-topbar">
          <div className="prework-instruction">
            <p><span className="title">Step 1. <span className="underline">Identify.</span></span> Select the following values you most identify with, then check your top 10.</p>
            <p>(Scroll down to see the full list of values). Note, you can type over a value to add a new one or change it.</p>
          </div>
          <div>
            <p className="selected-count bold">{selected.reduce((partial, s) => partial+s, 0)} selected ({10-selected.reduce((partial, s) => partial+s, 0)} remaining)</p>
          </div>
        </div>

        <div className="container-valuesgrid">
          {values.map((value, i) =>
            <ValueSelector  key={`value-${i}`}
                            text={value}
                            selected={selected[i]}
                            index={i}
                            cbText={handleValueChange}
                            cbSelected={handleSelectedChange} />
          )}
          <div></div>
          <div className="mobile-row">
            <p className="selected-count bold right-align">{selected.reduce((partial, s) => partial+s, 0)} selected ({10-selected.reduce((partial, s) => partial+s, 0)} remaining)</p>
          </div>
        </div>

        <div className="container-buttons">
          <button className="button-prework" onClick={() => saveData(cbPrevious)}>Save & Previous</button>
          <button className="button-prework" onClick={() => saveData()}>Save</button>
          <button className="button-prework" onClick={() => saveData(cbNext)}>Save & Next</button>
        </div>
      </div>
    </>
  );
}

export default Identifying;
