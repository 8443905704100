import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver'

import OutputValueRepresentation from '../../components/OutputValueRepresentation.js';
import Loading from '../../components/Loading.js';

import '../../styles/live/Output2.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function Output({cbPrevious, cbFinish, user, download}) {
  const [name, setName] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [values, setValues] = useState([]);
  const [definitions, setDefinitions] = useState([]);
  const [originalIndexes, setOriginalIndexes] = useState([]);
  const [percentLived, setPercentLived] = useState([]);
  const [loading, setLoading] = useState(false);

  const year = (new Date()).getFullYear();

  const downloadOutput = (filename) => {
    setLoading(true);
    axios.get(`${API_ROUTE}/event/event_output`, {
      withCredentials: true,
      params: new URLSearchParams({uid: user, page: 'live'}),
      responseType: 'arraybuffer'
    }).then((res) => {
      const pdf = new Blob([res.data], { type: 'application/pdf' });
      saveAs(pdf, filename);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  useEffect(() => {
    const responseUrl = user ? `${API_ROUTE}/response/participant_byid` : `${API_ROUTE}/response/participant`;
    const responseOptions = user ? {
      withCredentials: true,
      params: new URLSearchParams({prework: true, uid: user})
    } : {
      withCredentials: true,
      params: new URLSearchParams({prework: true})
    };

    axios.get(responseUrl, responseOptions).then((res) => {
      let newValues = res.data.values;
      let newDefinitions = res.data.descriptions;
      let newOriginalIndexes = res.data.originalIndexes;
      let newPercentLived = res.data.percentLived;

      setValues(newValues);
      setDefinitions(newDefinitions);
      setOriginalIndexes(newOriginalIndexes);
      setPercentLived(newPercentLived);
    });

    const eventUrl = user ? `${API_ROUTE}/event/participant_event_byid` : `${API_ROUTE}/event/participant_event`;
    const eventOptions = user ? {
      withCredentials: true,
      params: new URLSearchParams({uid: user})
    } : {
      withCredentials: true
    };

    axios.get(eventUrl, eventOptions).then((res) => {
      let newEventName = res.data.event.eventName;
      let newEventDate = new Date(res.data.event.eventDate);

      setEventName(newEventName);
      setEventDate(newEventDate);
    });

    const userUrl = user ? `${API_ROUTE}/user/info_byid` : `${API_ROUTE}/user/info`;
    const userOptions = user ? {
      withCredentials: true,
      params: new URLSearchParams({uid: user})
    } : {
      withCredentials: true
    };

    axios.get(userUrl, userOptions).then((res) => {
      const newName = `${res.data.user.forename} ${res.data.user.surname}`;
      setName(newName);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <>
      <div>
        {loading && (
          <Loading />
        )}
      </div>
      <div className="container-output2" style={download && {margin: "16px 12px", padding: 0}}>
        <div className="container-output2header">
          <div>
            <img className="logo" src="/images/PursuitLogo.png" alt="Pursuit Inc." />
          </div>
          <div className="container-header-title">
            <div className="title-name">
              {name}
            </div>
          </div>
          <div className="container-header-title">
            <div className="date">
              [{eventName}/{eventDate.toDateString()}]
            </div>
          </div>
        </div>

        <div className="container-output2content">
          <div className="left-col">
            <div className="">
              <div className="title">Go To Values</div>
              <div className="subtitle">
                "What I bring & leverage with others to move things forward."
              </div>
            </div>
            <div className="grid-item">
              <OutputValueRepresentation index={1} value={values[1]} lived={percentLived[1]} definition={definitions[1]} />
            </div>
            <div className="grid-item">
              <OutputValueRepresentation index={2} value={values[2]} lived={percentLived[2]} definition={definitions[2]} />
            </div>
            <div className="grid-item">
              <OutputValueRepresentation index={3} value={values[3]} lived={percentLived[3]} definition={definitions[3]} />
            </div>
            <div className="grid-item">
              <OutputValueRepresentation index={4} value={values[4]} lived={percentLived[4]} definition={definitions[4]} />
            </div>
          </div>

          <div className="center-col">
            <div className="">
              <div className="title">Driver Value</div>
              <div className="subtitle">
                “What is critical for me to have present and honoured in my environment & relationships to connect & flow.”
              </div>
            </div>
            <div className="grid-item">
              <OutputValueRepresentation index={0} value={values[0]} lived={percentLived[0]} definition={definitions[0]} />
            </div>
            <div className="grid-item">
              <img id="output-graphic" className="graphic-img" src="/images/OutputGraphic.png" alt="Output" />
            </div>
            <div className="">
              <div className="title">Result Value</div>
              <div className="subtitle">
                "What I deeply desire as a meaningful outcome for myself & others."
              </div>
            </div>
            <div className="grid-item">
              <OutputValueRepresentation index={9} value={values[9]} lived={percentLived[9]} definition={definitions[9]} />
            </div>
          </div>

          <div className="right-col">
            <div className="">
              <div className="title">Accelerator Values</div>
              <div className="subtitle">
                "What I leverage to resource myself & others to get unstuck & move forward again."
              </div>
            </div>
            <div className="grid-item">
              <OutputValueRepresentation index={5} value={values[5]} lived={percentLived[5]} definition={definitions[5]} />
            </div>
            <div className="grid-item">
              <OutputValueRepresentation index={6} value={values[6]} lived={percentLived[6]} definition={definitions[6]} />
            </div>
            <div className="grid-item">
              <OutputValueRepresentation index={7} value={values[7]} lived={percentLived[7]} definition={definitions[7]} />
            </div>
            <div className="grid-item">
              <OutputValueRepresentation index={8} value={values[8]} lived={percentLived[8]} definition={definitions[8]} />
            </div>
          </div>

          <div className="left">
            <div>Ideal Environment I Create</div>
            <div>Relating With Others</div>
            <div>How I Initiate</div>
          </div>

          <div className="right">
            <div>How I Initiate</div>
            <div>Relating With Others</div>
            <div>Ideal Energy I Create</div>
          </div>

          <div className="bottom-left">Co-creating With Flow</div>
          <div className="bottom-center">Direction & Possibilites</div>
          <div className="bottom-right">Creating Breakthroughs</div>
        </div>

        {!download && (
          <div className="container-buttons">
            <button className="button-live" onClick={cbPrevious}>Previous</button>
            <button className="button-live" onClick={() => downloadOutput(`${name}-${eventName}-${eventDate.toDateString()}.pdf`)}>Download</button>
            <button className="button-live" onClick={cbFinish}>{user ? "Finish" : "Logout"}</button>
          </div>
        )}

        {download && (
          <div className="container-printfooter">
            <p>Copyright © 2007-{year} Pursuit Development Labs Inc. All Rights Reserved.</p>
          </div>
        )}
      </div>
    </>
  );
}

export default Output;
