import React, {useState, useEffect} from 'react';
import axios from 'axios';

import ValueDefinition from '../../components/ValueDefinition.js';
import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/prework/Defining.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function Defining({cbPrevious, cbNext, user}) {
  const [values, setValues] = useState([]);
  const [definitions, setDefinitions] = useState([]);
  const [originalIndexes, setOriginalIndexes] = useState([]);
  const [percentLived, setPercentLived] = useState([]);
  const [infoPopup, setInfoPopup] = useState(null);

  const setValue = (i, value) => {
    const newValues = [...values];
    newValues[i] = value;
    setValues(newValues);
  };

  const setDefinition = (i, definition) => {
    const newDefinitions = [...definitions];
    newDefinitions[i] = definition;
    setDefinitions(newDefinitions);
  };

  const saveData = (next) => {
    let dataUpdate = {
      values: Array.from(values, (val, i) => {return {value: val, originalIndex: originalIndexes[i]}}),
      descriptions: definitions,
      percentLived: percentLived,
      completedMark: 1
    };

    if (user) {
      dataUpdate.uid = user;
    }
    const url = user ? `${API_ROUTE}/response/participant_byid` : `${API_ROUTE}/response/participant`;

    axios.put(url, dataUpdate, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      // console.log(res);
      // alert('Successfully saved!');
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        if (next) {
          next();
        }
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  useEffect(() => {
    const url = user ? `${API_ROUTE}/response/participant_byid` : `${API_ROUTE}/response/participant`;
    const options = user ? {
      withCredentials: true,
      params: new URLSearchParams({prework: true, uid: user})
    } : {
      withCredentials: true,
      params: new URLSearchParams({prework: true})
    };

    axios.get(url, options).then((res) => {
      let newValues = res.data.values;
      let newDefinitions = res.data.descriptions;
      let newOriginalIndexes = res.data.originalIndexes;
      let newPercentLived = res.data.percentLived;

      setValues(newValues);
      setDefinitions(newDefinitions);
      setOriginalIndexes(newOriginalIndexes);
      setPercentLived(newPercentLived);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  }, []);

  return (
    <>
      {infoPopup && (infoPopup)}
      <div className="container-defining">
        <p><span className="title">Step 2. <span className="underline">Define.</span></span> For each 'Value' in your 'top 10', provide a brief definition describing what the value means to you in your own language.</p>

        <div className="container-valuedefinitions">
          {values.map((value, i) =>
            <ValueDefinition  key={i}
                              index={i}
                              value={value}
                              definition={definitions[i]}
                              setValue={setValue}
                              setDefinition={setDefinition} />
          )}
        </div>

        <div className="container-buttons">
          <button className="button-prework" onClick={() => saveData(cbPrevious)}>Save & Previous</button>
          <button className="button-prework" onClick={() => saveData()}>Save</button>
          <button className="button-prework" onClick={() => saveData(cbNext)}>Save & Next</button>
        </div>
      </div>
    </>
  );
}

export default Defining;
