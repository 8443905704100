import React from 'react';

import '../styles/components/OutputValueRepresentation.css';

function ValueRepresentation({index, value, lived, definition}) {
  return (
    <>
      <div className="container-outputvaluerepresentation">
        <div className="grid-item-outputvaluerepresentation rank">
          <p>{index+1}.</p>
        </div>
        <div className="grid-item-outputvaluerepresentation value">
          <p className="value-text">{value}</p>
        </div>
        <div className="grid-item-outputvaluerepresentation lived">
          <p className="">{lived}% Lived</p>
        </div>
        <div className="grid-item-outputvaluerepresentation definition">
          <p>{definition}</p>
        </div>
      </div>
    </>
  );
}

export default ValueRepresentation;
