import React from 'react';

//Styles
import '../styles/components/SessionHeader.css';

function SessionHeader({pageName, pageInfo}) {
  return (
    <header>
      <div className="header-session">
        <div className="container-logo">
          <a href="/">
            <img className="logo" src="/images/PursuitLogo.png" alt="Pursuit Inc." />
          </a>
        </div>
        <div>
          <h2>{pageName}</h2>
        </div>
        <div>
          <h2>{pageInfo}</h2>
        </div>
      </div>
    </header>
  );
}

export default SessionHeader;
