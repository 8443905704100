import React, {useState} from 'react';

import '../styles/components/EmailConfirmation.css';

const uploadEmailTemplate = `You have requested to edit your values.

Below you will find a logon link which will allow you to edit your values system.

If there are any issues please reach out to your facilitator or info@pursuitinc.com.

Regards,
Pursuit Development Labs`;

function EmailConfirmation({cbConfirm, cbCancel, message, editEmail}) {
  const [emailBody, setEmailBody] = useState(uploadEmailTemplate);

  const handleEmailBodyChange = (event) => {
    setEmailBody(event.target.value);
  };

  return (
    <>
      <div className="container-emailconfirmationfloat">
        <div className="container-emailconfirmation">
          <p>{message}</p>
          {editEmail && (
            <>
              <p>Participant Email:</p>
              <div className="container-emailbodyedit">
                <div className="">Hi [Participant Name Inserted Here],</div>
                <textarea className="textarea-emailbodyedit"
                          value={emailBody}
                          onChange={handleEmailBodyChange}
                          placeholder=""
                          rows={8} />
              </div>
            </>
          )}
          <div className="container-buttons">
            <button className="button-emailconfirmation" onClick={() => {editEmail ? cbConfirm(emailBody) : cbConfirm()}}>Send Email</button>
            <button className="button-emailconfirmation cancel" onClick={cbCancel}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailConfirmation;
