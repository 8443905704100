import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver'

import Lived from './Lived.js';
import Loading from '../../components/Loading.js';

import '../../styles/prework/Completion.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function Completion({cbPrevious, cbFinish, cbDownload, user}) {
  const [downloading, setDownloading] = useState(false);
  const [name, setName] = useState("");
  const [pdfComponent, setPdfComponent] = useState(null);
  const [eventDate, setEventDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const downloadRef = useRef(null);

  const printOutput = async (output) => {
    const pdf = await output;

    const a = document.createElement('a');
    a.href = URL.createObjectURL(pdf);
    a.download = `${name}-Pre-work.pdf`;
    a.click();
    setPdfComponent(null);
  };

  const downloadData = () => {
    setLoading(true);
    const filename = `${name}-Pre-work.pdf`;

    axios.get(`${API_ROUTE}/event/event_output`, {
      withCredentials: true,
      params: new URLSearchParams({uid: user, page: 'prework'}),
      responseType: 'arraybuffer'
    }).then((res) => {
      const pdf = new Blob([res.data], { type: 'application/pdf' });
      saveAs(pdf, filename);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  useEffect(() => {
    let name = '';
    const url = user ? `${API_ROUTE}/user/info_byid` : `${API_ROUTE}/user/info`;
    const options = user ? {
      withCredentials: true,
      params: new URLSearchParams({prework: true, uid: user})
    } : {
      withCredentials: true,
      params: new URLSearchParams({prework: true})
    };
    axios.get(url, options).then((res) => {
      const name = `${res.data.user.forename}${res.data.user.surname}`;
      setName(name);

      const url = `${API_ROUTE}/event`;
      const options = {
        withCredentials: true,
        params: new URLSearchParams({prework: true, eid: res.data.user.event})
      };

      axios.get(url, options).then((res) => {
        let date = new Date(res.data.event.eventDate);
        date.setTime(date.getTime() + (8 * 60*60*1000));
        setEventDate(date);
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <>
      {loading && (<Loading />)}
      <div className="container-completion">
        <p className="title">Thank You For Completing Your Pre-work!</p>
        <p>To download a copy of your Pre-work click the 'Download' button below.</p>
        <p>
          You may adjust your answers by selecting ‘previous’ or by revisiting TheValuesSystem.com.
          Otherwise, we will see you on {eventDate.toDateString()} to <span className="italics">Uncover Your Values System&trade;</span>.
        </p>

        <div className="container-buttons">
          <button className="button-prework" onClick={cbPrevious}>Previous</button>
          <button className="button-prework" onClick={downloadData}>Download</button>
          <button className="button-prework" onClick={cbFinish}>{user ? "Finish" : "Logout"}</button>
        </div>
      </div>
    </>
  );
}

export default Completion;
