import React, {useState} from 'react';
import axios from 'axios';

import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/admin/AddSuperAdmin.css';

const FIELD_NAMES = ["First Name", "Last Name", "Email"];
const FIELD_NAMES_EXTRA = ["First Name", "Last Name", "Email", "Password"];
// let mockFieldValues = ["", "", "", "", ""];
// const mockSuperAdmins = ["John Smith", "Jane Doe", "Mohammed Hussein", "John Smith", "Jane Doe", "Mohammed Hussein", "John Smith", "Jane Doe", "Mohammed Hussein"];

const API_ROUTE = process.env.REACT_APP_API_URL;

function AddSuperAdmin({cbClose}) {
  const [fieldValues, setFieldValues] = useState(["", "", "", ""]);
  const [infoPopup, setInfoPopup] = useState(null);

  const FIELDS = FIELD_NAMES_EXTRA;

  const handleInputChange = (event) => {
    let index = event.target.id.split('-');
    index = parseInt(index[index.length-1]);

    let newFieldValues = [...fieldValues];
    newFieldValues[index] = event.target.value;
    setFieldValues(newFieldValues);
  };

  const saveData = () => {
    let registerObj = {
      accessLevel: 2,
      forename: fieldValues[0],
      surname: fieldValues[1],
      email: fieldValues[2],
      password: fieldValues[3]
    };

    axios.post(`${API_ROUTE}/auth/register`, registerObj, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
      // alert('Super-Admin Added Successfully');
      cbClose();
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  return (
    <>
      {infoPopup && (infoPopup)}
      <div className="container-addsuperadminfloat">
        <div className="container-addsuperadmin">
          <div className="title underline">Add Super-Admin</div>
          <div className="container-input">
            {FIELDS.map((fieldName, i) =>
              <div className="grid-item">
                <div className="left">{fieldName}</div>
                <input  id={`pa-input-${i}`}
                        className="right text-superadminaccount"
                        type="text"
                        value={fieldValues[i]}
                        onChange={handleInputChange}
                        placeholder={`Enter The ${fieldName.replace('Confirm ', '')}`} />
              </div>
            )}
          </div>

          <div className="container-buttons">
            <button className="button save" onClick={saveData}>Save</button>
            <button className="button cancel" onClick={cbClose}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddSuperAdmin;
