import React, {useContext, useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';

import {AuthContext} from '../context/AuthContext.js';

import '../styles/ResetPassword.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function ResetPassword() {
  const {lid} = useParams();
  const {login, loggedIn} = useContext(AuthContext);
  const navigate = useNavigate();
  const [invalidLink, setInvalidLink] = useState(false);
  const [uid, setUid] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleReset = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    const passwordData = {
      uid: uid,
      lid: lid,
      password: password
    };
    axios.put(`${API_ROUTE}/auth/reset_password`, passwordData, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const loginData = {
        email: res.data.user.email,
        password: password
      };
      axios.post(`${API_ROUTE}/auth/login`, loginData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        login(res.data.accessLevel);
        navigate('/');
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    axios.get(`${API_ROUTE}/logon_link`, {
      params: new URLSearchParams({lid: lid})
    }).then((res) => {
      if (res.data.link.used) {
        setInvalidLink(true);
      } else {
        setInvalidLink(false);
        setUid(res.data.link.uid);
      }
    }).catch((err) => {
      console.log(err);
      setInvalidLink(true);
      navigate('/');
    });
  }, [lid]);

  return (
    <>
      <div className="container-resetpassword">
        {invalidLink ?
          <div className="title">Invalid Link</div>
        :
          <>
            <div className="title">Create a Password</div>
            <form id="pass-form" onSubmit={(e) => handleReset(e)}>
              <div className="container-inputs">
                <div className="label">Password: </div>
                <input  id={`signup-password`}
                        className="input-register"
                        type="password"
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        value={password}
                        onChange={handlePasswordChange}
                        required />
                <div className="label">Confirm Password: </div>
                <input  id={`signup-confirmpassword`}
                        className="input-register"
                        type="password"
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required />
              </div>
            </form>

            <div className="container-buttons">
              <button type="submit" form="pass-form">Set Password</button>
            </div>
          </>
        }
      </div>
    </>
  );
}

export default ResetPassword;
