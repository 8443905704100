import React, {useState} from 'react';
import axios from 'axios';

import Confirmation from '../../components/Confirmation.js';
import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/admin/EditFacilitator.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function EditFacilitator({cbClose, facilitatorData}) {
  const [facilitator, setFacilitator] = useState(facilitatorData);
  const [password, setPassword] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [infoPopup, setInfoPopup] = useState(null);

  const handleInputChange = (event) => {
    let newFacilitator = {...facilitator};
    newFacilitator[event.target.id] = event.target.value;
    setFacilitator(newFacilitator);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const saveData = () => {
    let userData = {
      uid: facilitator.uid,
      forename: facilitator.forename,
      surname: facilitator.surname,
      email: facilitator.email,
      password: password,
      portalLink: facilitator.portal_link
    };

    axios.put(`${API_ROUTE}/user/update`, userData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
        cbClose();
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  const deleteData = () => {
    let userData = {
      uid: facilitator.uid
    };

    axios.delete(`${API_ROUTE}/user/delete`, {
      data: userData,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
      // alert('Facilitator deletion successful');
      setDeleting(false);
      cbClose();
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  const confirmDelete = () => {
    setDeleting(true);
  };

  const cancelDelete  = () => {
    setDeleting(false);
  };

  const confirmationMessage = `Please confirm you want to delete ${facilitator.forename} ${facilitator.surname} with email ${facilitator.email} from the system. This cannot be undone.`;

  return (
    <>
      {infoPopup && (infoPopup)}
      <div className="container-editfacilitatorfloat">
        {deleting && (
          <Confirmation cbDelete={deleteData}
                        cbCancel={cancelDelete}
                        message={confirmationMessage} />
        )}
        <div className="container-editfacilitator">
          <div className="title underline">Edit Guide</div>
          <div className="container-input">
            <div className="grid-item">
              <div className="left">First Name</div>
              <input  id="forename"
                      className="right text-facilitatoraccount"
                      type="text"
                      value={facilitator.forename}
                      onChange={handleInputChange}
                      placeholder="Enter The Forename" />
            </div>
            <div className="grid-item">
              <div className="left">Last Name</div>
              <input  id="surname"
                      className="right text-facilitatoraccount"
                      type="text"
                      value={facilitator.surname}
                      onChange={handleInputChange}
                      placeholder="Enter The Surname" />
            </div>
            <div className="grid-item">
              <div className="left">Email</div>
              <input  id="email"
                      className="right text-facilitatoraccount"
                      type="text"
                      value={facilitator.email}
                      onChange={handleInputChange}
                      placeholder="Enter The Email" />
            </div>
            <div className="grid-item">
              <div className="left">New Password</div>
              <input  id="password"
                      className="right text-facilitatoraccount"
                      type="text"
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Enter A New Password" />
            </div>
            <div className="grid-item">
              <div className="left">Portal Link</div>
              <input  id="portal_link"
                      className="right text-facilitatoraccount"
                      type="text"
                      value={facilitator.portal_link}
                      onChange={handleInputChange}
                      placeholder="Enter The Portal Link For This User" />
            </div>
          </div>

          <div className="container-buttons">
            <button className="button save" onClick={saveData}>Save</button>
            <button className="button delete" onClick={confirmDelete}>Delete</button>
            <button className="button cancel" onClick={cbClose}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditFacilitator;
