import React, {useEffect} from 'react';
import { useParams } from "react-router-dom";

import Lived from '../subpages/prework/Lived.js';
// import Output from '../subpages/live/Output.js';
import Output from '../subpages/live/Output2.js';

function Print() {
  const {uid, page} = useParams();

  const renderPage = () => {
    if (page == 'live') {
      return <Output  user={uid}
                      dataLoaded={() => {}}
                      download={true} />;
    } else if (page == 'prework') {
      return <Lived user={uid}
                    download={true} />;
    }
  };

  useEffect(() => {
    const footer = document.querySelector('footer');
    if (footer) {
      footer.style.display = 'none';
    }
  }, []);

  return (
    <>
      {renderPage()}
    </>
  );
}

export default Print;
