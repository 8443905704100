import React, {useContext, useState} from 'react';

import CookieConfirmation from '../components/CookieConfirmation.js';

//Home variant imports
import ParticipantHome from '../subpages/home/ParticipantHome.js';
import FacilitatorHome from '../subpages/home/FacilitatorHome.js';
import SuperAdminHome from '../subpages/home/SuperAdminHome.js';

import {AuthContext} from '../context/AuthContext.js';

import '../styles/Home.css';

function Home() {
  const {accessLevel, firstLogin, setFirstLogin} = useContext(AuthContext);

  const closeCookiePopup = () => {
    setFirstLogin(false);
  };

  const renderHomepage = () => {
    switch (accessLevel) {
      case 0:
        return <ParticipantHome />;
      case 1:
        return <FacilitatorHome />;
      case 2:
        return <SuperAdminHome />;
      default:
        return <div></div>;
    }
  }

  return (
    <>
      {firstLogin && (
        <CookieConfirmation cbClose={closeCookiePopup} />
      )}
      <div className="home-container">
        {renderHomepage()}
      </div>
    </>
  );
}

export default Home;
