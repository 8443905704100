import React from 'react';

import '../styles/components/ParticipantProgress.css';

function ParticipantProgress({preworkProgress}) {
  if (preworkProgress.length != 4) {
    return (<div></div>);
  }

  return (
    <>
      <div className="container-participantprogress">
        {preworkProgress.map((val, i) =>
          <>
            {val ?
              <div className="complete">{i+1}</div> :
              <div className="incomplete">{i+1}</div>
            }
            {i < 3 && (
              <div>-</div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ParticipantProgress;
