import React from 'react';

import '../../styles/prework/Legal.css';

function Legal({cbAgree}) {
  return (
    <>
      <div className="container-preworklegal">
        <p><span className="bold italics">We are so excited to support you in creating your Values System&trade;!</span> To ensure your experience is rewarding for both you and us <span className="italics">(Pursuit Development Labs Inc. who owns the site content, processes and software)</span> please review and agree to the following.</p>
        <p><span className="underline">Our Commitment to Ensure Privacy of Your Information.</span> Pursuit Development Labs Inc. respects your privacy. We will not share your personal information and it is only accessible by our administrators. We will only use your name and contact information to maintain access to our site.</p>
        <p><span className="underline">Your Agreement When Using this Site.</span> You agree to only retain a copy of the completed materials and use the related tools for your personal use. You also agree to not duplicate, publish, modify or otherwise distribute the developed materials and processes and/or use with others unless specifically authorized by Pursuit Development Labs Inc. to do so.</p>
        <br />
        <p>If you have any questions, please feel free to contact me.</p>
        <p>Have a great experience uncovering and exploring your Values System&trade;!</p>
        <p>
          Mike Farley{"\n"}
          COO, Pursuit Development Labs Inc.{"\n"}
          info@pursuitinc.com
        </p>

        <div className="container-buttons">
          <div></div>
          <button className="button-prework" onClick={cbAgree}>I Agree</button>
        </div>
      </div>
    </>
  );
}

export default Legal;
