import { createContext, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const API_ROUTE = process.env.REACT_APP_API_URL;

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [accessLevel, setAccessLevel] = useState(-1);
  const [firstLogin, setFirstLogin] = useState(false);
  const navigate = useNavigate();

  const login = (newAccessLevel) => {
    setAccessLevel(newAccessLevel);
    if (!loggedIn) {
      setLoggedIn(true);
      // navigate('/');
    }
  };

  const logout = () => {
    setLoggedIn(false);
    axios.post(`${API_ROUTE}/auth/logout`, {}, {
      withCredentials: true
    }).then((res) => {
      navigate('/login');
    }).catch((err) => {
      console.log(err);
      navigate('/login');
    });
  };

  const isLoggedIn = () => {
    return loggedIn;
  };

  const values = {
    loggedIn,
    isLoggedIn,
    login,
    logout,
    accessLevel,
    setFirstLogin,
    firstLogin
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
