import React from 'react';
import {useNavigate} from 'react-router-dom';

import '../styles/components/ToPrework.css';

function ToPrework({uid}) {
  const navigate = useNavigate();
  const link = uid ? `/prework/${uid}` : '/prework';

  return (
    <>
      <div className="container-topreworkfloat">
        <div className="container-toprework">
          <p>You have not completed your pre-work. Please do so now.</p>
          <button className="button-toprework" onClick={() => navigate(link)}>Pre-work</button>
        </div>
      </div>
    </>
  );
}

export default ToPrework;
