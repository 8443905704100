import React from 'react';

import '../../styles/prework/Introduction.css';

function Introduction({cbPrevious, cbNext}) {
  return (
    <>
      <div className="container-introduction">
        <p><span className="bold italics">You are now ready to complete your Pre-work</span> in preparation for your session to Uncover Your Values System&trade;. Ideally when completing this exercise, create a space where you will not be interrupted and will be free of distractions. It will take you approximately 30-45 minutes to complete.</p>
        <p>As you complete this exercise, we recommend that you follow your instincts and not overthink your responses.</p>
        <br />
        <p className="bold list">There are 4 steps to completing your Pre-work.</p>
        <p className="list"><span className="bold list-green">Step 1.</span> <span className="bold underline list-green">Identify</span> your top 10 values.</p>
        <p className="list"><span className="bold list-green">Step 2.</span> <span className="bold underline list-green">Define</span> what your values mean to you.</p>
        <p className="list"><span className="bold list-green">Step 3.</span> <span className="bold underline list-green">Rank</span> your top 10 values.</p>
        <p className="list"><span className="bold list-green">Step 4.</span> <span className="bold underline list-green">% Lived</span> associating what % out of 100 you live that value in your life and work.</p>
        <br />
        <p>You will know you are finished your Pre-work when you see <span className="italics">“Thank You for Completing Your Pre-work”.</span></p>

        <div className="container-buttons">
          <button className="button-prework" onClick={cbPrevious}>Previous</button>
          <button className="button-prework" onClick={cbNext}>Next</button>
        </div>
      </div>
    </>
  );
}

export default Introduction;
