import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';

import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/admin/TeamOutput.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function TeamOutput() {
  const [searchQuery, setSearchQuery] = useState("");
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const allRef = useRef(null);
  const [infoPopup, setInfoPopup] = useState(null);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const downloadTeamOutput = (id, name) => {
    const prevButtonStyle = document.querySelector(`#${id} button`).style;
    document.querySelector(`#${id} button`).style = 'visibility: hidden;';
    htmlToImage.toCanvas(document.getElementById(id)).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const img = new Image();
      img.src = imgData;

      img.onload = () => {
        let orientation = 'portrait';
        if (img.width > img.height) {
          orientation = 'landscape';
        }
        const doc = jsPDF({
          orientation: orientation,
          unit: 'mm',
          format: [img.width+50, img.height+50]
        });
        doc.addImage(imgData, 'PNG', 25, 25, img.width, img.height);
        const outputName = `${name}.pdf`;
        doc.save(outputName);
        document.querySelector(`#${id} button`).style = prevButtonStyle;
      }
    });
  };

  const downloadAll = () => {
    const buttons = document.getElementsByTagName('button');
    let styles = [];
    for (let b of buttons) {
      styles.push(b.style);
      b.style = 'visibility: hidden;';
    }
    htmlToImage.toCanvas(allRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const img = new Image();
      img.src = imgData;

      img.onload = () => {
        let orientation = 'portrait';
        if (img.width > img.height) {
          orientation = 'landscape';
        }
        const doc = jsPDF({
          orientation: orientation,
          unit: 'mm',
          format: [img.width+50, img.height+50]
        });
        doc.addImage(imgData, 'PNG', 25, 25, img.width, img.height);
        let queries = searchQuery.split(/[\s,]/);
        queries = queries.filter((q) => {
          return q.length > 0;
        });
        const outputName = `${queries.join("_")}${queries.length > 0 ? "_" : ""}TeamOutput.pdf`;
        doc.save(outputName);
        for (let i = 0; i < buttons.length; i++) {
          buttons[i].style = styles[i];
        }
      }
    });
  };

  useEffect(() => {
    let newEvents = [...allEvents];
    let queries = searchQuery.split(/[\s,]/);
    queries = queries.filter((q) => {
      return q.length > 0;
    });
    if (queries.length > 0) {
      newEvents = newEvents.filter((event) => {
        let output = false;
        for (let q of queries) {
          output |= event.eventName.toLowerCase().includes(q.toLowerCase());
        }
        return output;
      });
    }
    setEvents(newEvents);
  }, [searchQuery]);

  const refreshEvent = () => {
    axios.get(`${API_ROUTE}/event/team_output`, {
      withCredentials: true
    }).then((res) => {
      setAllEvents(res.data.team_output);
      let newSearchQuery = searchQuery.trim() + " ";
      setSearchQuery(newSearchQuery);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  useEffect(() => {
    axios.get(`${API_ROUTE}/event/team_output`, {
      withCredentials: true
    }).then((res) => {
      setEvents(res.data.team_output);
      setAllEvents(res.data.team_output);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  }, []);

  return (
    <>
      {infoPopup && (infoPopup)}
      <div className="container-teamoutput">
        <div className="container-topbar">
          <div className="container-search">
            <div className="grid-title">Search: </div>
            <input  id="input-search"
                    className="input-search"
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search by event name (separate by comma)" />
          </div>
          <div className="container-headerbuttons">
            <button className="button-teamoutputall" onClick={()=>downloadAll()}>Download All</button>
            <div className="container-refresh">
              <img  className="refresh"
                    src="/images/refresh-icon.png"
                    alt="Refresh"
                    title="Refresh"
                    tabindex="0"
                    onClick={refreshEvent}/>
            </div>
          </div>
        </div>

        {events.length == 0 && (
          <div className="text-info">
            No events with name '{searchQuery}' found, please change your search or add an event.
          </div>
        )}
        <div ref={allRef}>
          {events.map((event, i) =>
            <div id={`teamoutput-${i}`} className="container-event">
              <div className="container-title">
                <div className="title">{event.eventName} [{event.sessionAccess}]</div>
                <button className="button-teamoutput" onClick={()=>downloadTeamOutput(`teamoutput-${i}`, `${event.eventName}-[${event.sessionAccess}]`)}>Download</button>
              </div>
              <div className="container-eventcontent">
                <div className="container-eventrow">
                  <div className="grid-item"></div>
                  <div className="grid-item subtitle driver">Driver Value</div>
                  <div className="grid-item subtitle result">Result Value</div>
                  <div className="grid-item subtitle goto subtitle-goto">GoTo Values</div>
                  <div className="grid-item subtitle accelerator subtitle-accelerator">Accelerator Values</div>
                </div>
                <div className="container-eventrow">
                  <div className="grid-item"></div>
                  <div className="grid-item subsubtitle">Being</div>
                  <div className="grid-item subsubtitle">Possibility</div>
                  <div className="grid-item subsubtitle">GoTo Values</div>
                  <div className="grid-item subsubtitle">Environment for Flow</div>
                  <div className="grid-item subsubtitle">Accelerator Values</div>
                  <div className="grid-item subsubtitle">Energy for Breakthrough</div>
                </div>
                {event.participants.map((participant, i) =>
                  <div className="container-eventrow">
                    <div className="grid-item">
                      <div className="name">{participant.forename} {participant.surname}</div>
                    </div>
                    <div className="grid-item">
                      <div className="driver">{participant.values[0]}</div>
                      <div className="">{participant.descriptions[0]}</div>
                    </div>
                    <div className="grid-item">
                      <div className="result">{participant.values[9]}</div>
                      <div className="">{participant.descriptions[9]}</div>
                    </div>
                    <div className="grid-item">
                      <div className="goto">2. {participant.values[1]}</div>
                      <div className="goto">3. {participant.values[2]}</div>
                      <div className="goto">4. {participant.values[3]}</div>
                      <div className="goto">5. {participant.values[4]}</div>
                    </div>
                    <div className="grid-item">
                      <div className="goto">5. {participant.values[4]}</div>
                      <div classname="">{participant.descriptions[4]}</div>
                    </div>
                    <div className="grid-item">
                      <div className="accelerator">6. {participant.values[5]}</div>
                      <div className="accelerator">7. {participant.values[6]}</div>
                      <div className="accelerator">8. {participant.values[7]}</div>
                      <div className="accelerator">9. {participant.values[8]}</div>
                    </div>
                    <div className="grid-item">
                      <div className="accelerator">9. {participant.values[8]}</div>
                      <div className="">{participant.descriptions[9]}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TeamOutput;
