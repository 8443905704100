import React from 'react';

import '../../styles/prework/IntroductionIdentifying.css';

function IntroductionIdentifying({cbPrevious, cbNext}) {
  return (
    <>
      <div className="container-introductionidentifying">
        <p className="title">Step 1. <span className="underline">Identify</span></p>

        <p>From the list of Values on the next page, you will review, and select the Values you most identify with (typically 10-15) and place a check mark beside your top 10.</p>
        <p className="no-bottom">Some considerations as you complete this step.</p>
        <div className="indented">
          <p>
            Choose the Values that have strong meaning to you and that you identify with the most.
          </p>
          <p>
            Typically, the values you choose will seem familiar (although you may surprise yourself) and you will identify with them as 'part' of who you are, what you 'stand for’, and what is most important to you.
          </p>
          <p className="no-bottom">Feel free to:</p>
          <div className="container-notes">
            <ul>
              <li>Add your own Values</li>
              <li>Re-word existing Values</li>
              <li>Combine Values if you must <span className="italics">(we are ultimately looking for you to be as clear as possible with what your top 10 Values mean to you)</span></li>
            </ul>
          </div>
        </div>

        <div className="container-buttons">
          <button className="button-prework" onClick={cbPrevious}>Previous</button>
          <button className="button-prework" onClick={cbNext}>Next</button>
        </div>
      </div>
    </>
  );
}

export default IntroductionIdentifying;
