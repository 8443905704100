import React, {useState} from 'react';

import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/admin/UploadEvent.css';

function UploadEvent({cbUpload, cbCancel}) {
  const [eventFile, setEventFile] = useState(null);
  const [infoPopup, setInfoPopup] = useState(null);

  const handleFileChange = (event) => {
    // setEventFile(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      setEventFile(JSON.parse(event.target.result));
    };
    fileReader.onerror = (err) => {
      console.log(err);
    };
    fileReader.readAsText(event.target.files[0]);
  };

  const uploadFile = () => {
    if (!eventFile) {
      const newInfoPopup = <InfoPopup message={"Please select a file"}
                                      success={false} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
      // alert('No file selected');
    } else {
      cbUpload(eventFile);
    }
  };

  return (
    <>
      {infoPopup && (infoPopup)}
      <div className="container-uploadeventfloat">
        <div className="container-uploadevent">
          <div className="container-input">
            <p>Upload Previously Saved Event Files For Editing: </p>
            <input type="file" name="event" onChange={handleFileChange} accept=".json" />
          </div>
          <div className="container-buttons">
            <button className="button-uploadevent upload" onClick={uploadFile}>Upload</button>
            <button className="button-uploadevent cancel" onClick={cbCancel}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadEvent;
