import React, {useState, useEffect} from 'react';
import axios from 'axios';

import InfoPopup from '../../components/InfoPopup.js';
import AddSuperAdmin from './AddSuperAdmin.js';
import EditSuperAdmin from './EditSuperAdmin.js';

import '../../styles/admin/SuperAdmins.css';

const API_ROUTE = process.env.REACT_APP_API_URL;

function SuperAdmins() {
  const [allSuperAdmins, setAllSuperAdmins] = useState([]);
  const [superAdmins, setSuperAdmins] = useState([]);
  const [addingSuperAdmin, setAddingSuperAdmin] = useState(false);
  const [editSuperAdminIndex, setEditSuperAdminIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState("");
  const [infoPopup, setInfoPopup] = useState(null);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  }

  const addSuperAdmin = () => {
    setAddingSuperAdmin(true);
  };

  const closeAddSuperAdmin = () => {
    setAddingSuperAdmin(false);
  };

  const editSuperAdmin = (index) => {
    setEditSuperAdminIndex(index);
  }

  const closeEditSuperAdmin = () => {
    setEditSuperAdminIndex(-1);
  }

  useEffect(() => {
    let newSuperAdmins = [...allSuperAdmins];
    newSuperAdmins = newSuperAdmins.filter((superAdmin) => {
      let forenameValid = superAdmin.forename.toLowerCase().includes(searchQuery.toLowerCase());
      let surnameValid = superAdmin.surname.toLowerCase().includes(searchQuery.toLowerCase());
      return forenameValid || surnameValid;
    });
    setSuperAdmins(newSuperAdmins);
  }, [searchQuery]);

  useEffect(() => {
    axios.get(`${API_ROUTE}/user/superadmins`, {
      withCredentials: true
    }).then((res) => {
      setSuperAdmins(res.data.users);
      setAllSuperAdmins(res.data.users);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    })
  }, [editSuperAdminIndex, addingSuperAdmin]);

  return (
    <>
      <div>
        {editSuperAdminIndex >= 0 && (
          <EditSuperAdmin cbClose={closeEditSuperAdmin}
                          superAdminData={superAdmins[editSuperAdminIndex]} />
        )}
        {addingSuperAdmin && (
          <AddSuperAdmin cbClose={closeAddSuperAdmin} />
        )}
        {infoPopup && (infoPopup)}
      </div>
      <div className="container-superadmins">
        <div className="container-topbar">
          <div className="container-search">
            <div className="grid-title">Search: </div>
            <input  id="input-search"
                    className="input-search"
                    type="text"
                    value={searchQuery}
                    onChange={handleInputChange}
                    placeholder="Search for a super-admin" />
          </div>
          <div>
            <button onClick={addSuperAdmin}>Add Super-Admin</button>
          </div>
        </div>

        <div className="container-superadminlist">
          <div className="grid-item grid-title">
            <div>First Name</div>
          </div>
          <div className="grid-item grid-title">
            <div>Last Name</div>
          </div>
          <div className="grid-item grid-title">
            <div>Email</div>
          </div>
          <div className="grid-item grid-title">
          </div>

          {superAdmins.map((superAdmin, i) =>
            <>
              <div className="grid-item">
                <div className="grid-text">{superAdmin.forename}</div>
              </div>
              <div className="grid-item">
                <div className="grid-text">{superAdmin.surname}</div>
              </div>
              <div className="grid-item">
                <div className="grid-text">{superAdmin.email}</div>
              </div>
              <div className="grid-item">
                <button onClick={() => editSuperAdmin(i)}>Edit</button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SuperAdmins;
